import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Stack, Typography } from '@mui/material';
import { CourseHero } from './components/CourseHero';
import { InfoRounded, MenuBookRounded } from '@mui/icons-material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { getActivityPath, TopicCard, TopicCardContainer, TopicCardHeader } from '../../quests/Quest';
import { SplitTopicActivities } from './components/TopicActivities';
import { usePlatformContext } from '../../platformContext';
import { useMemo } from 'react';
import { usePlatformNavigate } from '../../navigation';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { learnAnalytics } from './learn.analytics';
import { getCourseState, saveCourseState, updateTopicState } from './courseState';
import { WithFooter } from '../../footer/WithFooter';
import { Skeleton } from '@securecodewarrior/design-system-react';
export const CourseDetailsView = ({ course }) => {
    var _a, _b, _c;
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const analytics = useMemo(() => learnAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const navigate = usePlatformNavigate();
    const preferredLanguage = ((_b = (_a = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.i18nLanguagePreference) || 'en';
    const courseStateFromLocalStorage = course && getCourseState(course);
    const { state } = useLocation();
    const highlightedActivity = state === null || state === void 0 ? void 0 : state.highlightedActivity;
    let highlightedActivityId = null;
    // this could find the wrong activity if the course was changed since starting to play
    if (highlightedActivity && courseStateFromLocalStorage) {
        const { chapterNr, topicNr, activityType, activityNr } = highlightedActivity;
        const topic = course.chapters[chapterNr - 1].topics[topicNr - 1];
        if (!courseStateFromLocalStorage.expandedTopics[course.language.id].includes(topic.category)) {
            courseStateFromLocalStorage.expandedTopics[course.language.id].push(topic.category);
            saveCourseState(courseStateFromLocalStorage);
        }
        const activities = activityType === 'challenge' ? topic.activities.concat(topic.validation) : topic.activities;
        highlightedActivityId = (_c = activities.filter((a) => a.kind === activityType)[activityNr - 1]) === null || _c === void 0 ? void 0 : _c.id;
    }
    const onStartActivity = (activity, course, topic) => {
        const activitypath = getActivityPath(activity, course);
        navigate.to({
            pathname: `learn/course/${course.language.id}/${activitypath}`,
            search: '',
            hash: '',
        });
        analytics.onActivityStarted(activity.in_progress
            ? 'started'
            : activity.finished
                ? 'completed'
                : activity.lastFailed
                    ? 'failed'
                    : 'not started', activity.id, activity.kind, topic.category, activity.kind === 'guideline' ? preferredLanguage : undefined);
    };
    return (_jsx(WithFooter, { wrapperComponent: "main", children: _jsxs(Stack, { sx: { alignSelf: 'center', width: '100%' }, children: [_jsx(CourseHero, { course: course }), _jsxs(Card, { sx: (theme) => ({
                        display: 'flex',
                        gap: 2,
                        alignSelf: 'center',
                        alignItems: 'center',
                        color: theme.palette.text.soft,
                        borderColor: theme.palette.container.border.active,
                        maxWidth: theme.typography.pxToRem(960),
                        px: 6,
                        py: 5,
                        my: 7,
                    }), children: [_jsx(InfoRounded, { fontSize: "large" }), _jsx(Typography, { color: (theme) => theme.palette.text.soft, children: _jsx(Trans, { i18nKey: "pages.learn.course.details.tip" }) })] }), course ? (course.chapters.map((chapter, chapterIndex) => (_jsxs(Stack, { flexGrow: "1", children: [chapterIndex > 0 && _jsx(DividerWithMargin, {}), _jsxs(ChapterContainer, { children: [_jsx(ChapterHeader, { chapter: chapter, chapterNr: chapterIndex + 1 }), chapter.topics.map((topic, topicIndex) => (_jsx(TopicCard, { chapterNr: chapterIndex + 1, initialCollapsed: !(courseStateFromLocalStorage === null || courseStateFromLocalStorage === void 0 ? void 0 : courseStateFromLocalStorage.expandedTopics[course.language.id].includes(topic.category)), nr: topicIndex + 1, topic: topic, onToggle: () => updateTopicState(topic.category, course.language.id), analytics: analytics, children: _jsx(SplitTopicActivities, { activities: topic.activities, validation: topic.validation, highlightedActivityId: highlightedActivityId, onStartActivity: (a) => onStartActivity(a, course, topic) }) }, topic.id)))] })] }, chapter.name)))) : (_jsxs(_Fragment, { children: [_jsxs(ChapterContainer, { children: [_jsx(ChapterHeader, {}), _jsx(TopicCardContainer, { children: _jsx(TopicCardHeader, {}) })] }), _jsx(DividerWithMargin, {}), _jsxs(ChapterContainer, { children: [_jsx(ChapterHeader, {}), _jsx(TopicCardContainer, { children: _jsx(TopicCardHeader, {}) })] })] }))] }) }));
};
const ChapterContainer = ({ children }) => (_jsx(Stack, { gap: 5, maxWidth: (theme) => theme.typography.pxToRem(960), alignSelf: "center", width: "100%", children: children }));
const DividerWithMargin = () => _jsx(Divider, { sx: { my: 5 } });
const ChapterHeader = ({ chapter, chapterNr }) => {
    var _a;
    const topicStats = chapter
        ? {
            completed: chapter.topics.reduce((acc, topic) => acc + (topic.completedAt ? 1 : 0), 0),
            total: chapter.topics.length,
        }
        : undefined;
    return (_jsxs(Stack, { direction: "row", justifyContent: "space-between", py: 3, alignItems: "center", children: [_jsxs(Stack, { direction: "row", gap: 2, px: 2, alignItems: "center", color: (theme) => theme.palette.text.soft, children: [_jsx(MenuBookRounded, { fontSize: "large" }), _jsx(Typography, { variant: "h5", component: chapterNr ? 'h2' : 'div', color: "inherit", children: chapterNr ? (_jsx(Trans, { i18nKey: "pages.learn.course.details.chapter", values: { nr: chapterNr } })) : (_jsx(Skeleton, { width: "100px", sx: { transform: 'scale(1, .8)' } })) }), _jsx(Typography, { variant: "h5", component: (chapter === null || chapter === void 0 ? void 0 : chapter.name) ? 'h3' : 'div', children: (_a = chapter === null || chapter === void 0 ? void 0 : chapter.name) !== null && _a !== void 0 ? _a : _jsx(Skeleton, { width: "150px", sx: { transform: 'scale(1, .8)' } }) })] }), _jsx(Typography, { variant: "subtitle1", component: "span", color: (theme) => theme.palette.text.title, children: topicStats ? (_jsx(Trans, { i18nKey: "pages.learn.course.details.topicsCompleted", values: topicStats })) : (_jsx(Skeleton, { width: "76px" })) })] }));
};
