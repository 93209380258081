import { usePlatformContext } from '../../platformContext';
import { initialState, useDashboard } from '../context/dashboardContext';
export const useDashboardAnalytics = () => {
    const { logAnalyticsEvent } = usePlatformContext();
    const { state } = useDashboard();
    const emitEvent = (eventName) => {
        const eventData = getEventData(state, eventName);
        // Check if state is initial or loading
        const isInitialLoad = eventName === 'loading_admin_dashboard' && JSON.stringify(state) === JSON.stringify(initialState);
        const isInterimLoad = eventName === 'loading_admin_dashboard' &&
            (eventData.active_learners_state === 'loading' ||
                eventData.learning_progress_state === 'loading' ||
                eventData.licence_usage_state === 'loading' ||
                eventData.trust_score_state === 'loading');
        if (eventName !== 'loading_admin_dashboard') {
            logAnalyticsEvent(eventData);
        }
        else if (isInitialLoad) {
            // Change from default which is populated so the event looks correct
            eventData.active_learners_state = 'loading';
            eventData.learning_progress_state = 'loading';
            eventData.licence_usage_state = 'loading';
            eventData.trust_score_state = 'loading';
            logAnalyticsEvent(eventData);
        }
        else if (!isInterimLoad) {
            // Loaded state: update event if it's a fully loaded dashboard
            eventData.event = 'view_admin_dashboard';
            logAnalyticsEvent(eventData);
        }
    };
    return { emitEvent };
};
const getCardState = (state, card) => {
    const cardState = state === null || state === void 0 ? void 0 : state.cards[card];
    if (cardState === null || cardState === void 0 ? void 0 : cardState.error)
        return 'error';
    if (cardState === null || cardState === void 0 ? void 0 : cardState.loading)
        return 'loading';
    return 'populated';
};
const getEventData = (state, eventName) => ({
    event: eventName,
    licence_usage_state: getCardState(state, 'licence-usage'),
    licence_usage_data: state.cards['licence-usage'].data,
    trust_score_state: getCardState(state, 'trust-score'),
    trust_score_data: state.cards['trust-score'].data,
    active_learners_state: getCardState(state, 'learner-engagement'),
    active_learners_data: state.cards['learner-engagement'].data,
    learning_progress_state: getCardState(state, 'learning-progress'),
    learning_progress_data: state.cards['learning-progress'].data,
});
