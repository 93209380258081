import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useApiClient } from '../../ApiContext';
import { CourseDetailsView } from './CourseDetailsView';
import { usePlatformContext } from '../../platformContext';
import { getCategoryName } from '../../utils/mapper';
export const CourseDetailsPage = () => {
    const { languageId } = useParams();
    if (!languageId) {
        throw new Error('Missing language ID');
    }
    const { handleErrorPromise, trpc } = useApiClient();
    const { setTranslatedCategories, metadata } = usePlatformContext();
    const [course, setCourse] = useState();
    useEffect(() => {
        handleErrorPromise(trpc.learn.course.getCourseForLanguage.query({ languageId }), (r) => {
            setCourse(r.course);
            const translatedCategories = {};
            for (const chapter of r.course.chapters) {
                for (const topic of chapter.topics) {
                    if (!translatedCategories[topic.category]) {
                        translatedCategories[topic.category] = getCategoryName(topic.category, metadata);
                    }
                }
            }
            setTranslatedCategories((prev) => (Object.assign(Object.assign({}, prev), translatedCategories)));
        });
    }, [languageId]); // eslint-disable-line react-hooks/exhaustive-deps
    return _jsx(CourseDetailsView, { course: course });
};
