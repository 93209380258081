// A partial list of LaunchDarkly feature flag names.
// See also the list in projects/portal-frontend/scw-angular/feature-flags/FeatureFlagsApi.js
export const featureFlags = {
    COMMS_CENTRE: 'comms-centre',
    COMMS_CENTRE_LEGACY: 'comms-centre-legacy',
    COMMS_CENTRE_QUILL: 'comms-centre-quill',
    CUSTOMER_ANALYTICS: 'customer-analytics',
    CUSTOMER_PLAN: 'customer-plan',
    CYBERMON: 'cybermon',
    DASHBOARD: 'dashboard-page',
    GOALS_ADMIN_MOST_COMMON_OBJECTIVE: 'goals-admin-most-common-objective',
    GOALS_ADMIN_TEAMS: 'goals-admin-teams',
    HIDE_TRAINING: 'hide-training',
    LEVEL_GROUPING: 'level-grouping-feature',
    MISSION_IN_COURSES: 'MISSION_IN_COURSES',
    MULTI_ASSESSMENTS: 'multi-assessments',
    MULTI_COMPANY_TOURNAMENT_BANNER: 'multi-company-tournament-banner',
    NEW_TOURNAMENTS_LIST: 'new-tournaments-list-page',
    NEW_USER_MANAGEMENT_PAGE: 'new-user-management-page',
    ONBOARDING_APP: 'onboarding-app',
    PUBLIC_TOURNAMENTS: 'public-tournaments',
    QUESTS: 'quests',
    QUESTS_V2: 'quests-v2',
    QUESTS_V3: 'quests-v3',
    REPORTING: 'reporting',
    SHOW_EXPLORE_MENU_ITEM: 'show-explore-menu-item',
    TOURNAMENT_INVITES: 'tournament-invites',
    VULNERABILITY_SUMMARY_REPORT: 'vulnerability-summary-report',
};
