import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, FormGroup, Stack, Typography, Unstable_Grid2 as Grid2, Divider, debounce, Tooltip } from '@mui/material';
import { useState, useId, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NoSearchResultAlert, Text } from '@securecodewarrior/design-system-react/lib/legacy';
import { textContainsSearch } from '../../../../utils/string';
import { SearchInput, Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { ChoiceToggleButton } from '../../atoms/Choice/ChoiceToggleButton';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { ChipWithClose } from '../../atoms/Chip/Chip';
import { CloseIconButton } from '../../atoms/CloseIconButton';
export const LanguageSelection = (props) => {
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const initiallySelectedLanguages = new Set(props.initiallySelectedLanguages);
    const [selectedLangs, setSelectedLangs] = useState(initiallySelectedLanguages);
    const languageSelectionRef = useRef(null);
    const languageNameInfoMap = useMemo(() => Object.fromEntries(Object.entries(props.collections).flatMap(([key, value]) => value.map((y) => [y.id, { collectionName: key, languageName: y.label }]))), [props.collections]);
    const isSelectionSame = (selection) => {
        return (selection.size === initiallySelectedLanguages.size &&
            [...selection].every((language) => initiallySelectedLanguages.has(language)));
    };
    const resetSearch = () => {
        setSearch('');
        props.onSearchReset();
    };
    const onLanguageToggled = (id) => setSelectedLangs((curr) => {
        const wasSelected = curr.has(id);
        props.onCategoryToggled(wasSelected, id);
        wasSelected ? curr.delete(id) : curr.add(id);
        return new Set(curr.values());
    });
    const onUpdateSearch = (searchTerm) => {
        setSearch(searchTerm);
        debouncedSearchAnalytics(searchTerm);
    };
    const debouncedSearchAnalytics = useMemo(() => debounce((searchTerm) => {
        props.onSearchUsed(searchTerm);
    }, 500), [props]);
    const selectionPills = Array.from(selectedLangs.values()).map((lang) => {
        const languageInfo = languageNameInfoMap[lang];
        if (!languageInfo)
            return null;
        return (_jsx(ChipWithClose, { label: `${languageInfo.collectionName}:`, suffix: languageInfo.languageName, onDelete: () => {
                var _a;
                if (languageSelectionRef.current) {
                    const chips = languageSelectionRef.current.querySelectorAll('.deletable');
                    const index = Array.from(chips).findIndex((c) => { var _a; return (_a = c.textContent) === null || _a === void 0 ? void 0 : _a.includes(languageInfo.languageName); });
                    const focusChip = chips[index - 1] || chips[index + 1];
                    (_a = focusChip === null || focusChip === void 0 ? void 0 : focusChip.querySelector('button')) === null || _a === void 0 ? void 0 : _a.focus();
                }
                onLanguageToggled(lang);
            } }, `chip_collection_${lang}`));
    });
    const filteredCollection = Object.entries(props.collections)
        .map(([label, languages]) => ({
        label,
        languages: languages.filter((l) => textContainsSearch(l.label, search)),
    }))
        .filter((x) => x.languages.length !== 0);
    return (_jsxs(Stack, { ref: languageSelectionRef, borderRadius: 3, height: "80vh", py: 8, position: "relative", bgcolor: (theme) => theme.palette.container.fill.card1, children: [_jsx(CloseIconButton, { "aria-label": t(props.onBack.labelKey), onClick: props.onBack.action, sx: {
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                } }), _jsxs(Stack, { px: 7, mb: 5, children: [_jsxs(Stack, { gap: 4, mb: 8, children: [_jsx(Typography, { variant: "h2", component: "h1", children: t(props.titleKey) }), _jsx(Typography, { sx: (theme) => ({ strong: { color: theme.palette.text.soft } }), children: _jsx(Trans, { i18nKey: props.subtitleKey }) })] }), _jsx(Stack, { maxWidth: "300px", children: _jsx(SearchInput, { fullWidth: true, onReset: resetSearch, search: search, setSearch: onUpdateSearch, placeholder: t(props.searchPlaceholderKey) }) })] }), filteredCollection.length === 0 ? (_jsx(NoSearchResultAlert, { onReset: resetSearch })) : (_jsx(Stack, { gap: 3, role: "list", overflow: "auto", maxHeight: "50vh", px: 7, mr: 4, sx: (theme) => ({
                    scrollbarColor: `${theme.palette.grayscale.bg6} ${theme.palette.grayscale.bg4}`,
                    scrollbarWidth: 'thin',
                    '::-webkit-scrollbar': {
                        background: theme.palette.grayscale.bg4,
                        width: '10px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: theme.palette.grayscale.bg6,
                        borderRadius: 4,
                    },
                }), children: filteredCollection.map((collection) => (_jsx(LanguageCollection, { label: collection.label, languages: collection.languages, toggleLanguage: onLanguageToggled, selectedLanguages: selectedLangs }, `collection_${collection.label}`))) })), _jsx(Box, { flexGrow: "1", mb: 7 }), _jsx(Divider, {}), _jsxs(Stack, { direction: { xs: 'column', sm: 'row' }, justifyContent: "space-between", gap: 2, px: 7, mt: 8, children: [props.required && (_jsxs(_Fragment, { children: [props.initiallySelectedLanguages.length !== 0 && (_jsx(Box, { flexGrow: { xs: 1, sm: 0 }, children: _jsx(Button, { variant: "outlined", onClick: props.onBack.action, sx: { flexShrink: 0 }, fullWidth: true, children: t(props.onBack.labelKey) }) })), selectedLangs.size === 0 ? (_jsxs(Stack, { direction: "row", alignItems: "center", gap: 2, children: [_jsx(ErrorOutlineIcon, { fontSize: "large", sx: (theme) => ({
                                            color: theme.palette.clickable.nav.item.default,
                                        }) }), _jsx(Typography, { children: _jsx(Trans, { i18nKey: "components.organisms.languageSelection.selectBeforeProceed" }) })] })) : (_jsx("div", { children: _jsx(Box, { flexGrow: "1", flexWrap: "wrap", display: { xs: 'none', lg: 'flex' }, gap: 2, justifyContent: props.initiallySelectedLanguages.length === 0 ? 'flex-start' : 'center', children: selectionPills }) }))] })), !props.required && (_jsxs(_Fragment, { children: [_jsx(Box, { flexGrow: { xs: 1, sm: 0 }, children: _jsx(Button, { variant: "outlined", onClick: props.onBack.action, sx: { flexShrink: 0 }, fullWidth: true, children: t(props.onBack.labelKey) }) }), selectedLangs.size > 0 && (_jsx("div", { children: _jsx(Box, { flexGrow: "1", flexWrap: "wrap", display: { xs: 'none', lg: 'flex' }, gap: 2, justifyContent: props.initiallySelectedLanguages.length === 0 ? 'flex-start' : 'center', children: selectionPills }) }))] })), _jsx(Box, { flexShrink: 0, flexGrow: { xs: 1, sm: 0 }, children: _jsx(Button, { variant: "contained", fullWidth: true, disabled: (props.required && selectedLangs.size === 0) || (props.changesRequired && isSelectionSame(selectedLangs)), onClick: () => props.onSubmit.action([...selectedLangs.values()]), children: t(props.onSubmit.labelKey, { count: selectedLangs.size }) }) })] }), _jsx(Stack, { direction: "row", flexWrap: "wrap", gap: 2, display: { xs: 'flex', lg: 'none' }, justifyContent: "center", mt: 3, children: selectionPills })] }));
};
export const LanguageCollection = (props) => {
    const a11yId = useId();
    const { t } = useTranslation();
    return (_jsx(Stack, { role: "listitem", "aria-labelledby": a11yId, bgcolor: (theme) => theme.palette.container.fill.body, border: (theme) => `1px solid ${theme.palette.container.border.default}`, borderRadius: 3, p: 5, children: _jsxs(Grid2, { container: true, spacing: 2, children: [_jsx(Grid2, { xs: 12, sm: 4, children: _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", component: "h2", fontWeight: 600, id: a11yId, textTransform: "capitalize", children: props.label }), _jsx(Typography, { variant: "caption", children: _jsx(Trans, { i18nKey: "components.organisms.languageSelection.languages", values: { count: props.languages.length } }) })] }) }), _jsx(Grid2, { xs: 12, sm: 8, children: _jsx(FormGroup, { sx: { flexWrap: 'wrap', flexDirection: { sm: 'column', md: 'row' }, gap: 2.5 }, children: props.languages
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((l) => l.disabled ? (_jsx(Tooltip, { title: t('pages.cybermon.content.notAvailable'), children: _jsx("span", { children: _jsx(ChoiceToggleButton, { onClick: () => !l.disabled && props.toggleLanguage(l.id), selected: props.selectedLanguages.has(l.id), value: l.id, disabled: l.disabled, style: { pointerEvents: 'none' }, children: _jsx(Text, { label: l.label, typographyProps: { variant: 'inherit' }, startIcon: _jsx(Box, { sx: (theme) => ({
                                                fontSize: theme.typography.pxToRem(22),
                                                color: theme.palette.container.border.input,
                                            }), className: `${l.iconClassName}` }) }) }, `toggle_${l.id}`) }) }, `tooltip_${l.id}`)) : (_jsx(ChoiceToggleButton, { onClick: () => props.toggleLanguage(l.id), selected: props.selectedLanguages.has(l.id), value: l.id, disabled: l.disabled, children: _jsx(Text, { label: l.label, typographyProps: { variant: 'inherit' }, startIcon: _jsx(Box, { sx: (theme) => ({
                                        fontSize: theme.typography.pxToRem(22),
                                        color: theme.palette.clickable.default,
                                    }), className: `${l.iconClassName}` }) }) }, `toggle_${l.id}`))) }) })] }) }));
};
