import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { languageSelectionAnalytics as getLanguageSelectionAnalyticsFunctions } from '../../../../common/components/organisms/LanguageSelector/LanguageSelectionAnalytics';
import { LanguageSelection, } from '../../../../common/components/organisms/LanguageSelector/LanguageSelection';
import { LanguageConfirmModal } from '../../components/LanguageConfirmModal';
import { usePlatformContext } from '../../../platformContext';
import { useMemo, useRef, useState } from 'react';
import { Dialog } from '@mui/material';
export const MyQuestsLanguageModal = (props) => {
    const [selectedLanguagesToConfirm, setSelectedLanguagesToConfirm] = useState([]);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const { logAnalyticsEvent } = usePlatformContext();
    const languageSelectionAnalytics = useMemo(() => {
        return getLanguageSelectionAnalyticsFunctions('myQuests', logAnalyticsEvent);
    }, [logAnalyticsEvent]);
    // Cache the available languages so the language selection modal can do its closing fadeout transition instead of disappearing instantly
    // The prop can't be made non-nullable because that would mean the dialog would only render if it's available, meaning the opening fadein transition will not work
    const cachedBackendData = useRef();
    if (props.open && props.backendData && props.backendData !== 'loading') {
        cachedBackendData.current = props.backendData;
    }
    return (_jsx(Dialog, { open: props.open, fullWidth: true, maxWidth: "xl", scroll: "body", onClose: () => {
            props.close();
            languageSelectionAnalytics.onCancelled();
        }, sx: (theme) => ({
            '& .MuiPaper-root:not(.MuiAlert-root)': {
                border: `1px solid ${theme.palette.container.border.default}`,
                backgroundColor: theme.palette.container.fill.card1,
                backgroundImage: 'unset',
                borderRadius: 3,
            },
        }), children: cachedBackendData.current && (_jsxs(_Fragment, { children: [_jsx(LanguageSelection, { required: true, searchPlaceholderKey: 'pages.learn.overview.languageSelection.searchPlaceholder', subtitleKey: 'pages.learn.overview.languageSelection.description', titleKey: 'pages.learn.overview.languageSelection.title', collections: cachedBackendData.current.availableLanguages, initiallySelectedLanguages: cachedBackendData.current.selectedLanguages, onSearchUsed: languageSelectionAnalytics.onSearchUsed, onCategoryToggled: languageSelectionAnalytics.onCategoryToggled, onSearchReset: languageSelectionAnalytics.onSearchReset, onBack: {
                        labelKey: 'pages.learn.overview.languageSelection.cancel',
                        action: () => {
                            props.close();
                            languageSelectionAnalytics.onCancelled();
                        },
                    }, onSubmit: {
                        labelKey: 'pages.learn.overview.languageSelection.submit',
                        action: (languages) => {
                            setSelectedLanguagesToConfirm(languages);
                            setConfirmationDialogOpen(true);
                        },
                    }, changesRequired: true }), _jsx(LanguageConfirmModal, { open: confirmationDialogOpen, onConfirm: () => {
                        props.saveSelectedLanguages(selectedLanguagesToConfirm);
                        setConfirmationDialogOpen(false);
                        props.close();
                        languageSelectionAnalytics.onSubmit(selectedLanguagesToConfirm);
                    }, onCancel: () => setConfirmationDialogOpen(false), languages: Object.values(cachedBackendData.current.availableLanguages)
                        .flat()
                        .filter((lang) => selectedLanguagesToConfirm.includes(lang.id)), languageConfirmLoading: false })] })) }));
};
