import { jsx as _jsx } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../molecules/ConfirmationDialog/ConfirmationDialog';
import { useEffect, useMemo } from 'react';
import { questDetailsAnalytics } from '../../../analytics/QuestDetails.analytics';
import { WarningRounded } from '@mui/icons-material';
import { usePlatformContext } from '../../../../platformContext';
const creationScenarios = {
    publish: {
        title: 'questsAdmin.components.molecules.confirmationDialog.confirmPublishDialog.published.title',
        buttonText: 'questsAdmin.components.molecules.confirmationDialog.confirmPublishDialog.published.buttonText',
        email: {
            text: 'questsAdmin.components.molecules.confirmationDialog.confirmPublishDialog.published.withEmailText',
            eventKey: 'publish with email',
        },
        noEmail: {
            text: 'questsAdmin.components.molecules.confirmationDialog.confirmPublishDialog.published.noEmailText',
            eventKey: 'publish without email',
        },
    },
    schedule: {
        title: 'questsAdmin.components.molecules.confirmationDialog.confirmPublishDialog.scheduled.title',
        buttonText: 'questsAdmin.components.molecules.confirmationDialog.confirmPublishDialog.scheduled.buttonText',
        email: {
            text: 'questsAdmin.components.molecules.confirmationDialog.confirmPublishDialog.scheduled.withEmailText',
            eventKey: 'schedule with email',
        },
        noEmail: {
            text: 'questsAdmin.components.molecules.confirmationDialog.confirmPublishDialog.scheduled.noEmailText',
            eventKey: 'schedule without email',
        },
    },
};
const getCreationScenarioKeys = (startDate, isEmailSendingEnabled) => {
    const publishOrSchedule = startDate <= new Date() ? creationScenarios.publish : creationScenarios.schedule;
    return Object.assign({ title: publishOrSchedule.title, buttonText: publishOrSchedule.buttonText }, (isEmailSendingEnabled ? publishOrSchedule.email : publishOrSchedule.noEmail));
};
export const ConfirmPublishDialog = ({ questStatus, startDate, isOpen, isEmailSendingEnabled, onConfirm, onCancel, }) => {
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const confirmCreationScenarioKeys = useMemo(() => getCreationScenarioKeys(startDate, isEmailSendingEnabled), [startDate, isEmailSendingEnabled]);
    useEffect(() => {
        if (isOpen) {
            analytics.onPublishQuestDialogOpened(questStatus, confirmCreationScenarioKeys.eventKey);
        }
    }, [analytics, isOpen, questStatus, confirmCreationScenarioKeys]);
    return (_jsx(ConfirmationDialog, { id: "confirm-creation-dialog", keepMounted: true, open: isOpen, color: "default", title: t(confirmCreationScenarioKeys === null || confirmCreationScenarioKeys === void 0 ? void 0 : confirmCreationScenarioKeys.title), confirmButtonLabel: t(confirmCreationScenarioKeys === null || confirmCreationScenarioKeys === void 0 ? void 0 : confirmCreationScenarioKeys.buttonText), cancelButtonLabel: t('questsAdmin.components.molecules.confirmationDialog.archiveDialog.cancelButtonLabel'), icon: _jsx(WarningRounded, {}), onConfirm: () => {
            analytics.onPublishQuestDialogClosed(questStatus, confirmCreationScenarioKeys.eventKey, true);
            onConfirm();
        }, onCancel: () => {
            analytics.onPublishQuestDialogClosed(questStatus, confirmCreationScenarioKeys.eventKey, false);
            onCancel();
        }, children: _jsx(Trans, { i18nKey: confirmCreationScenarioKeys === null || confirmCreationScenarioKeys === void 0 ? void 0 : confirmCreationScenarioKeys.text }) }));
};
