import { jsx as _jsx } from "react/jsx-runtime";
import { UserManagement } from './UserManagement';
import { usePlatformNavigate } from '../navigation';
import { usePlatformContext } from '../platformContext';
import { featureFlags } from '../../common';
export const UserManagementRoute = () => {
    var _a, _b;
    const navigate = usePlatformNavigate();
    const ctx = usePlatformContext();
    const newUserManagementPageVisible = ctx.flagsEnabled.includes(featureFlags.NEW_USER_MANAGEMENT_PAGE) &&
        ((_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes('company admin'));
    if (!newUserManagementPageVisible) {
        navigate.toState('home');
        return;
    }
    return _jsx(UserManagement, {});
};
