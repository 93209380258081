var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, LinearProgress, linearProgressClasses, Skeleton, Stack, Typography, useTheme, } from '@mui/material';
import { Badge } from '../../../questsAdmin/components/atoms/Badge';
import { Trans, useTranslation } from 'react-i18next';
import { elbeeHighImgUrl, elbeeLowImgUrl, elbeeMediumImgUrl } from './SkillLevelImageData';
const skillPropertiesByStatus = (theme) => ({
    completed: {
        avatarSrc: elbeeHighImgUrl,
        avatarShadow: `0px 0px 30px 0px rgba(11, 204, 226, 0.30)`,
        label: 'pages.myQuests.components.skillLevel.allCaughtUp',
        progressColor: theme.palette.text.status.success,
        badgeVariant: 'success',
    },
    mandatory: {
        avatarSrc: elbeeLowImgUrl,
        avatarShadow: undefined,
        label: 'pages.myQuests.components.skillLevel.mandatoryRequired',
        progressColor: theme.palette.text.status.warning,
        badgeVariant: 'error',
    },
    optional: {
        avatarSrc: elbeeMediumImgUrl,
        avatarShadow: undefined,
        label: 'pages.myQuests.components.skillLevel.optionalAvailable',
        progressColor: theme.palette.text.soft,
        badgeVariant: 'accent4',
    },
});
export const SkillLevel = (_a) => {
    var { variant, skillLevel } = _a, stackProps = __rest(_a, ["variant", "skillLevel"]);
    const theme = useTheme();
    const { t } = useTranslation();
    const skillProperties = variant ? skillPropertiesByStatus(theme)[variant] : undefined;
    return (_jsxs(Stack, Object.assign({ direction: "row", gap: 5, width: theme.typography.pxToRem(400), alignItems: "center" }, stackProps, { children: [_jsxs(Stack, { sx: { gap: 2.75, flexGrow: 1 }, children: [skillProperties ? (_jsx(Badge, { TypographyProps: { sx: { width: 'fit-content' } }, label: t(skillProperties.label), variant: skillProperties.badgeVariant })) : (_jsx(Skeleton, { width: "90%", sx: (theme) => ({
                            transform: 'unset',
                            /*properties copied from badge to ensure it's the same height and general shape:*/
                            lineHeight: theme.typography.caption.lineHeight,
                            border: `1px solid transparent`,
                            padding: theme.spacing(1, 2),
                            borderRadius: 2,
                        }) })), _jsx(LinearProgress, { sx: (theme) => ({
                            height: theme.typography.pxToRem(10),
                            borderRadius: theme.shape.border.radius.md,
                            backgroundColor: theme.palette.container.border.default,
                            [`& .${linearProgressClasses.bar}`]: {
                                backgroundColor: skillProperties ? skillProperties.progressColor : 'transparent',
                                borderRadius: theme.shape.border.radius.md,
                            },
                        }), "aria-label": t('pages.myQuests.components.skillLevel.progressAriaLabel'), variant: "determinate", value: skillLevel !== undefined ? (skillLevel * 100) / 1000 : 0 }), _jsx(Typography, { sx: (theme) => ({ color: theme.palette.container.border.active }), children: skillLevel !== undefined ? (_jsx(Trans, { i18nKey: "pages.myQuests.components.skillLevel.points", values: { points: skillLevel, maxPoints: 1000 }, children: _jsx("b", { style: { color: theme.palette.text.body } }) })) : (_jsx(Skeleton, { variant: "text", width: "80%" })) })] }), skillProperties ? (_jsx(Avatar, { src: skillProperties.avatarSrc, alt: t('pages.myQuests.components.skillLevel.avatarAlt'), sx: (theme) => ({
                    width: theme.typography.pxToRem(100),
                    height: theme.typography.pxToRem(100),
                    boxShadow: skillProperties.avatarShadow,
                }) })) : (_jsx(Skeleton, { variant: "circular", width: theme.typography.pxToRem(100), height: theme.typography.pxToRem(100) }))] })));
};
