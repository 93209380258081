import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SwitchContainer } from '../communications/components/SwitchContainer';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { CommunicationsIcon } from '../common/icons/CommunicationsIcon';
import { Container } from '@securecodewarrior/design-system-react';
import { CommunicationsSection as Section } from '../communications/Communications.styled';
import { Button, Switch, Tooltip } from '@securecodewarrior/design-system-react/lib/wanda';
import { forwardRef } from 'react';
const LearningModuleHeader = () => {
    const { t } = useTranslation();
    return (_jsxs(Stack, { direction: "row", alignItems: "center", gap: 3, children: [_jsx(CommunicationsIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(40) }) }), _jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", children: t('pages.administration.communicationsCentre.company') }), _jsx(Typography, { variant: "h2", children: t('pages.administration.learningModules.configureLearningModules') })] })] }));
};
export const LearningModulePage = (props) => {
    const { t } = useTranslation();
    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit();
    };
    const SaveButton = forwardRef((saveButtonProps, ref // https://mui.com/material-ui/react-tooltip/#disabled-elements
    ) => (_jsx("span", Object.assign({ style: { width: 'fit-content' } }, saveButtonProps, { ref: ref, children: _jsx(Button, { variant: "contained", type: "submit", sx: { width: 'fit-content' }, disabled: props.loading || !(props.modules.quests || props.modules.guidedLearning), children: t('pages.administration.learningModules.saveButton') }) }))));
    SaveButton.displayName = 'SaveButton';
    return (_jsx(Container, { children: _jsxs(Stack, { rowGap: 10, children: [_jsx(LearningModuleHeader, {}), _jsx(Section, { children: _jsx("form", { onSubmit: onSubmit, children: _jsxs(Stack, { gap: 8, children: [_jsxs(Stack, { gap: 2, children: [_jsx(Typography, { variant: "h3", children: t('pages.administration.learningModules.recommended') }), _jsx(SwitchContainer, { title: t('pages.administration.learningModules.quests.title'), description: t('pages.administration.learningModules.quests.caption'), switchComponent: _jsx(Switch, { checked: props.modules.quests, onChange: (_event, checked) => props.onChange('quests', checked), inputProps: { 'aria-label': t('pages.administration.learningModules.quests.title') } }) })] }), _jsxs(Stack, { gap: 4, children: [_jsx(Typography, { variant: "h3", children: t('pages.administration.learningModules.legacy') }), _jsx(SwitchContainer, { title: t('pages.administration.learningModules.guidedLearning.title'), description: t('pages.administration.learningModules.guidedLearning.caption'), switchComponent: _jsx(Switch, { checked: props.modules.guidedLearning, onChange: (_event, checked) => props.onChange('guidedLearning', checked), inputProps: { 'aria-label': t('pages.administration.learningModules.guidedLearning.title') } }) }), _jsx(SwitchContainer, { title: t('pages.administration.learningModules.legacyReporting.title'), description: t('pages.administration.learningModules.legacyReporting.caption'), switchComponent: _jsx(Switch, { checked: props.modules.legacyReporting, onChange: (_event, checked) => props.onChange('legacyReporting', checked), inputProps: { 'aria-label': t('pages.administration.learningModules.legacyReporting.title') } }) })] }), !props.loading && (props.modules.quests || props.modules.guidedLearning) ? (_jsx(SaveButton, {})) : (_jsx(Tooltip, { title: t('pages.administration.learningModules.selectAtLeastOne'), arrow: true, children: _jsx(SaveButton, {}) }))] }) }) })] }) }));
};
