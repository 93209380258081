import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { ChallengePlayerWrapper, Skeleton as ChallengePlayerWrapperSkeleton } from './ChallengePlayerWrapper';
import { useApiClient } from '../../ApiContext';
import { VideoPlayerBase } from '../../players/VideoPlayerBase';
import { MissionPlayerWrapper } from './MissionPlayerWrapper';
import { usePlatformNavigate } from '../../navigation';
import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CodingLabWrapper } from './CodingLabWrapper';
import { NavigationBar } from '../../navigation/NavigationBar';
import { GuidelinePlayer } from '../../players/GuidelinePlayer';
import { usePlatformContext } from '../../platformContext';
import { mapCategory } from '../../utils/mapper';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../commonStyle';
import { Divider } from '../../Divider';
import { questAnalytics } from '../QuestAnalytics';
import { WithFooter } from '../../footer/WithFooter';
import { NewCodingLabAvailable } from '../../../common/components/organisms/NewCodingLabAvailable/NewCodingLabAvailable';
import { ActivityStatusBanner } from '../../../common/components/organisms/Activities/ActivityStatusBanner';
import { ActivityStatusPage } from '../../../common/components/organisms/Activities/ActivityStatusPage';
export const QuestActivityFromRouteParams = () => {
    const { questId, chapterNr: cNr, topicNr: tNr, activityType, activityNr: aNr } = useParams();
    const chapterNr = parseInt(cNr !== null && cNr !== void 0 ? cNr : '');
    const topicNr = parseInt(tNr !== null && tNr !== void 0 ? tNr : '');
    const activityNr = parseInt(aNr !== null && aNr !== void 0 ? aNr : '');
    if (!questId ||
        (activityType !== 'video' &&
            activityType !== 'guideline' &&
            activityType !== 'challenge' &&
            activityType !== 'coding_lab' &&
            activityType !== 'mission' &&
            activityType !== 'walkthrough') ||
        isNaN(chapterNr) ||
        isNaN(topicNr) ||
        isNaN(activityNr)) {
        throw new Error('Invalid parameters');
    }
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const props = {
        questId,
        chapterNr,
        topicNr,
        activityType,
        activityNr,
        analytics,
        analyticsScope: 'quests',
        returnBasePath: 'quests',
        nextActivityBasePath: `quests/${questId}`,
    };
    return _jsx(QuestActivity, Object.assign({}, props));
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export const QuestActivity = (props) => {
    var _a, _b, _c;
    const location = useLocation();
    const navigate = usePlatformNavigate();
    const { trpc, handleErrorPromise } = useApiClient();
    const metadata = usePlatformContext().metadata;
    const [onMarkAsComplete, setOnMarkAsComplete] = useState();
    const [enableMarkAsComplete, setEnableMarkAsComplete] = useState(false);
    const secondsSpent = useRef();
    const [completionStatus, setCompletionStatus] = useState('in_progress');
    const [shouldShowUpdatedLab, setShouldShowUpdatedLab] = useState(false);
    const [discardingLab, setDiscardingLab] = useState(false);
    const [cooldownExpiresAt, setCooldownExpiresAt] = useState(new Date());
    const analytics = props.analytics;
    const preferredLanguage = ((_c = (_b = (_a = usePlatformContext().sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.i18nLanguagePreference) || 'en';
    const [response, setResponse] = useState();
    const onVideoCompleted = useCallback((timeSpent) => {
        secondsSpent.current = Math.round(timeSpent);
        setEnableMarkAsComplete(true);
    }, []);
    const fetch = (redoIfCompleted = false) => {
        setResponse(undefined);
        setEnableMarkAsComplete(false);
        setOnMarkAsComplete(undefined);
        setCompletionStatus('in_progress');
        handleErrorPromise(trpc.quests.startOrContinueActivity.mutate(Object.assign(Object.assign({}, props), { redoIfCompleted })), (response) => {
            if (response.kind === 'ok') {
                if (response.activity.kind === 'guideline') {
                    const { category, contentId, attemptId } = response.activity;
                    setOnMarkAsComplete(() => {
                        return () => {
                            setEnableMarkAsComplete(false);
                            handleErrorPromise(trpc.quests.finishGuideline.mutate({ questId: props.questId, attemptId }), () => {
                                analytics.onActivityFinished(contentId, 'guideline', category, preferredLanguage);
                                setCompletionStatus('passed');
                            });
                        };
                    });
                }
                if (response.activity.kind === 'video') {
                    const videoId = response.activity.video.contentId;
                    const category = response.activity.video.category;
                    setOnMarkAsComplete(() => {
                        return () => {
                            setEnableMarkAsComplete(false);
                            handleErrorPromise(trpc.quests.finishVideo.mutate({
                                questId: props.questId,
                                videoId,
                                secondsSpent: secondsSpent.current,
                            }), () => {
                                analytics.onActivityFinished(videoId, 'video', category);
                                setCompletionStatus('passed');
                            });
                        };
                    });
                }
                if (response.activity.kind === 'coding_lab') {
                    setShouldShowUpdatedLab(!!response.activity.newVersionAvailable);
                }
            }
            else if (response.kind === 'already_failed') {
                setCooldownExpiresAt(response.cooldownExpiresAt);
            }
            setResponse(response);
        });
    };
    useEffect(fetch, [props.questId, props.chapterNr, props.topicNr, props.activityType, props.activityNr]); // eslint-disable-line react-hooks/exhaustive-deps
    const nextActivity = useCallback(() => {
        const nextActivity = response === null || response === void 0 ? void 0 : response.nextActivity;
        if (nextActivity) {
            analytics.onNextActivityClicked(nextActivity.contentId, nextActivity.activityType, nextActivity.category);
            navigate.to({
                pathname: `/${props.nextActivityBasePath}/chapter/${nextActivity.chapterNr}/topic/${nextActivity.topicNr}/${nextActivity.activityType}/${nextActivity.activityNr}`,
                search: '',
                hash: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, props.questId, response, analytics]);
    const goBack = (component) => {
        var _a;
        analytics.onBackFromActivityClicked(component, props.activityType);
        return navigate.to(((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) || { pathname: `/${props.returnBasePath}`, search: '', hash: '' }, {
            state: { highlightedActivity: props },
        });
    };
    const discardCodingLab = (attemptId) => {
        setDiscardingLab(true);
        handleErrorPromise(trpc.codingLabs.discard.mutate({ attemptId }), () => {
            fetch();
            setDiscardingLab(false);
        });
    };
    const onClickBackToActivity = props.activityType === 'guideline' || props.activityType === 'video' ? fetch : undefined;
    const activityStatus = (response === null || response === void 0 ? void 0 : response.kind) === 'ok'
        ? completionStatus
        : (response === null || response === void 0 ? void 0 : response.kind) === 'already_completed'
            ? 'passed'
            : (response === null || response === void 0 ? void 0 : response.kind) === 'already_failed'
                ? 'failed'
                : 'in_progress';
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const showPlayer = (response) => {
        const a = response.activity;
        return a.kind === 'video' ? (_jsx(VideoPlayerBase, { video: a.video, analyticsScope: props.analyticsScope, onComplete: onVideoCompleted })) : a.kind === 'guideline' ? (_jsx(GuidelinePlayer, { id: a.contentId, analyticsScope: "quests", style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }), guideline: a.guideline, title: mapCategory(a.category, metadata).name, category: a.category, onScrolledToEnd: () => setEnableMarkAsComplete(true), onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }) })) : a.kind === 'challenge' ? (_jsx(ChallengePlayerWrapper, { baseUrl: new URL(a.playerUrl), onComplete: (passed) => {
                setCompletionStatus(passed ? 'passed' : 'failed');
                if (!passed) {
                    setCooldownExpiresAt(new Date(new Date().getTime() + 5 * 60 * 1000));
                }
                analytics.onActivityFinished(a.contentId, 'challenge', a.category);
            } })) : a.kind === 'coding_lab' ? (_jsxs(_Fragment, { children: [shouldShowUpdatedLab && (_jsx(NewCodingLabAvailable, { onClickLoadOldSession: () => {
                        setShouldShowUpdatedLab(false);
                    }, onClickRestartLab: () => {
                        discardCodingLab(a.attemptId);
                    }, scope: props.analyticsScope, labId: a.contentId.split(':')[0], disabled: discardingLab })), _jsx(CodingLabWrapper, { baseUrl: a.playerUrl, sessionId: a.sessionId, hidden: shouldShowUpdatedLab, onComplete: () => {
                        analytics.onActivityFinished(a.contentId, 'coding_lab', a.category);
                        setCompletionStatus('passed');
                    } })] })) : a.kind === 'mission' || a.kind === 'walkthrough' ? (_jsx(MissionPlayerWrapper, { missionId: a.contentId, language: a.language, playerUrl: a.playerUrl, onComplete: () => {
                analytics.onActivityFinished(a.contentId, a.kind, a.category);
                setCompletionStatus('passed');
            } })) : (assertNever(a, 'invalid state'));
    };
    const showAlreadyCompleted = (response) => {
        return response.activity.kind === 'guideline' ? (_jsxs(GuidelinePlayer, { id: response.activity.contentId, analyticsScope: props.analyticsScope, style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }), guideline: response.activity.guideline, title: mapCategory(response.activity.category, metadata).name, category: response.activity.category, onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }), children: [_jsx(ActivityStatusBanner, { scope: props.analyticsScope, variant: "already_completed", contentType: props.activityType, contentId: response.activity.contentId, onClickStartRefresher: () => fetch(true) }), _jsx(Divider, {})] })) : response.activity.kind === 'video' ? (_jsxs(_Fragment, { children: [_jsx(ActivityStatusBanner, { scope: props.analyticsScope, variant: "already_completed", contentType: props.activityType, contentId: response.activity.contentId, onClickStartRefresher: () => fetch(true) }), _jsx(Divider, {}), _jsx(VideoPlayerBase, { video: response.activity.video, analyticsScope: props.analyticsScope })] })) : (_jsx(ActivityStatusPage, { scope: props.analyticsScope, variant: "already_completed", contentType: props.activityType, contentId: response.activity.contentId, onClickStartRefresher: () => fetch(true) }));
    };
    return (_jsxs(WithFooter, { children: [activityStatus === 'in_progress' ? (_jsx(NavigationBar, { type: props.activityType, module: props.analyticsScope, onBackNavigation: () => goBack('NavigationBar'), enableMarkAsComplete: enableMarkAsComplete, onMarkAsComplete: onMarkAsComplete, status: activityStatus, onClickNextActivity: (response === null || response === void 0 ? void 0 : response.nextActivity) ? nextActivity : undefined })) : (_jsx(NavigationBar, { type: props.activityType, module: props.analyticsScope, onBackNavigation: () => goBack('NavigationBar'), status: activityStatus, date: (response === null || response === void 0 ? void 0 : response.kind) === 'already_completed'
                    ? response.lastCompleted
                    : (response === null || response === void 0 ? void 0 : response.kind) === 'already_failed'
                        ? response.lastFailed
                        : new Date(), onClickNextActivity: (response === null || response === void 0 ? void 0 : response.nextActivity) ? nextActivity : undefined })), response ? (response.kind === 'ok' ? (completionStatus === 'in_progress' ? (showPlayer(response)) : completionStatus === 'failed' ? (_jsx(ActivityStatusPage, { scope: props.analyticsScope, variant: "failed", contentType: props.activityType, contentId: response.activity.contentId, onClickReplay: () => fetch(true), canRetryAt: cooldownExpiresAt })) : completionStatus === 'passed' ? (_jsx(ActivityStatusPage, { scope: props.analyticsScope, variant: "success", contentType: props.activityType, contentId: response.activity.contentId, onClickBackToActivity: onClickBackToActivity })) : (assertNever(completionStatus, 'invalid activity status'))) : response.kind === 'already_completed' ? (showAlreadyCompleted(response)) : response.kind === 'already_failed' ? (_jsx(ActivityStatusPage, { scope: props.analyticsScope, variant: "already_failed", contentType: props.activityType, contentId: response.activity.contentId, onClickReplay: () => fetch(true), canRetryAt: cooldownExpiresAt })) : (assertNever(response, 'invalid state'))) : props.activityType === 'video' ? (_jsx(VideoPlayerBase, { video: undefined, analyticsScope: props.analyticsScope })) : props.activityType === 'challenge' ? (_jsx(ChallengePlayerWrapperSkeleton, {})) : (_jsx(_Fragment, {}))] }));
};
