import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { Divider as MuiDivider, Stack, styled, Typography } from '@mui/material';
import { ActivityRow } from './ActivityRow';
export const TopicSection = ({ kind, activities, onStartActivity, highlightedActivityId, backupLanguage, }) => {
    const { t } = useTranslation();
    return (_jsxs(Stack, { gap: 5, children: [kind === 'quiz' && (_jsx(Typography, { variant: "caption", sx: (theme) => ({
                    '& > strong': { color: theme.palette.text.soft },
                    color: theme.palette.text.body,
                }), children: _jsx(Trans, { i18nKey: "pages.quests.quizDescription" }) })), _jsx(Stack, { gap: 2, role: "list", "aria-label": t(`pages.quests.${kind}`), children: activities.map((a) => (_jsx(ActivityRow, { activity: a, highlighted: !!highlightedActivityId && highlightedActivityId === a.id, onClick: () => onStartActivity(a), kind: kind, backupLanguage: backupLanguage }, `activity_${a.id}`))) })] }));
};
const Divider = styled(MuiDivider)(({ theme }) => ({
    borderColor: theme.palette.container.border.default,
}));
export const TopicActivities = (props) => {
    var _a, _b;
    return (_jsxs(_Fragment, { children: [props.activities.length > 0 && (_jsxs(_Fragment, { children: [_jsx(TopicSection, { onStartActivity: props.onStartActivity, backupLanguage: props.backupLanguage, kind: "activities", activities: props.activities, highlightedActivityId: props.highlightedActivityId }), ((_a = props.validation) === null || _a === void 0 ? void 0 : _a.length) > 0 && _jsx(Divider, {})] })), ((_b = props.validation) === null || _b === void 0 ? void 0 : _b.length) > 0 && (_jsx(TopicSection, { onStartActivity: props.onStartActivity, backupLanguage: props.backupLanguage, kind: "quiz", activities: props.validation, highlightedActivityId: props.highlightedActivityId }))] }));
};
