var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql } from 'urql';
import { z } from 'zod';
const SELECTED_GOAL_KEY = 'dashboard-selected-goal';
const GOALS_MAX = 10000;
const LIST_GOALS_QUERY = gql `
  query GoalsDetails($goalsMax: Int) {
    goals {
      goalsDetails(first: $goalsMax) {
        edges {
          node {
            goalSourceId
            name
          }
        }
      }
    }
  }
`;
const GOAL_STATISTICS_QUERY = gql `
  query GoalStatistics($goalId: String!) {
    goals {
      report(filter: { goalSourceId: $goalId }) {
        details {
          goalSourceId
          startDate
          endDate
          statistics {
            totalLearners
            completedLearners
            inProgressLearners
            notStartedLearners
            matchedLearners
          }
        }
      }
    }
  }
`;
const statisticsSchema = z.object({
    totalLearners: z.number().nullable().default(0),
    matchedLearners: z.number().nullable().default(0),
    completedLearners: z.number().nullable().default(0),
    inProgressLearners: z.number().nullable().default(0),
    notStartedLearners: z.number().nullable().default(0),
});
const rawGoalsListResponse = z.object({
    goals: z.object({
        goalsDetails: z.object({
            edges: z.array(z.object({
                node: z.object({
                    goalSourceId: z.string(),
                    name: z.string(),
                }),
            })),
        }),
    }),
});
const rawGoalStatisticsResponse = z.object({
    goals: z.object({
        report: z.object({
            details: z.object({
                goalSourceId: z.string(),
                startDate: z.string(),
                endDate: z.string(),
                statistics: statisticsSchema,
            }),
        }),
    }),
});
const learningProgressGoalsSchema = z.object({
    name: z.string(),
    goalId: z.string(),
    participantCount: z.number(),
    startDate: z.date(),
    status: z.string(),
});
const learningProgressReportingSchema = z.object({
    goalSourceId: z.string(),
    percentageCompleted: z.number(),
    numberOfLearnersCompletedGoal: z.number(),
    totalAssignedLearners: z.number(),
    learnersWithObjectiveAssigned: z.number(),
    startDate: z.date(),
    endDate: z.date(),
});
const getSelectedGoal = (sortedGoals, latestGoal) => {
    const savedGoalId = localStorage.getItem(SELECTED_GOAL_KEY);
    if (!savedGoalId) {
        return latestGoal;
    }
    const savedGoal = sortedGoals.find((goal) => goal.goalId === savedGoalId);
    if (!savedGoal) {
        localStorage.removeItem(SELECTED_GOAL_KEY);
        return latestGoal;
    }
    return savedGoal;
};
const transformGoalStatistics = (data) => {
    var _a, _b, _c, _d;
    const { details } = data.goals.report;
    const { statistics } = details;
    const totalSum = ((_a = statistics.completedLearners) !== null && _a !== void 0 ? _a : 0) + ((_b = statistics.inProgressLearners) !== null && _b !== void 0 ? _b : 0) + ((_c = statistics.notStartedLearners) !== null && _c !== void 0 ? _c : 0);
    const percentageCompleted = totalSum === 0 ? 0 : (((_d = statistics.completedLearners) !== null && _d !== void 0 ? _d : 0) / totalSum) * 100;
    return learningProgressReportingSchema.parse({
        goalSourceId: details.goalSourceId,
        percentageCompleted,
        numberOfLearnersCompletedGoal: statistics.completedLearners,
        totalAssignedLearners: statistics.totalLearners,
        learnersWithObjectiveAssigned: statistics.matchedLearners,
        startDate: new Date(details.startDate),
        endDate: new Date(details.endDate),
    });
};
const fetchGoalStatistics = (urqlClient, goalId) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, error } = yield urqlClient.query(GOAL_STATISTICS_QUERY, { goalId }).toPromise();
    if (error)
        throw error;
    const parsedStatistics = rawGoalStatisticsResponse.parse(data);
    return transformGoalStatistics(parsedStatistics);
});
const fetchGoalsList = (urqlClient) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, error } = yield urqlClient.query(LIST_GOALS_QUERY, { goalsMax: GOALS_MAX }).toPromise();
    if (error)
        throw error;
    const parsedData = rawGoalsListResponse.parse(data);
    return parsedData.goals.goalsDetails.edges;
});
// Main Export
export const fetchLearningProgress = (trpc, urqlClient) => __awaiter(void 0, void 0, void 0, function* () {
    const goals = yield trpc.goalsAdmin.list.query();
    if (goals.kind !== 'ok') {
        throw new Error('Failed to fetch goals');
    }
    try {
        const sortedGoals = goals.data.published.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
        const filteredGoals = sortedGoals.filter((goal) => goal.status !== 'archived');
        if (!filteredGoals.length) {
            return { onboarding: true };
        }
        const selectedGoal = getSelectedGoal(sortedGoals, filteredGoals[0]);
        const goalsInReporting = yield fetchGoalsList(urqlClient);
        const isGoalPendingInReporting = !goalsInReporting.some((edge) => edge.node.goalSourceId === (selectedGoal === null || selectedGoal === void 0 ? void 0 : selectedGoal.goalId));
        if (isGoalPendingInReporting) {
            return {
                goals: filteredGoals,
                selectedGoal,
                goalsReportingData: undefined,
                onboarding: false,
            };
        }
        const goalsReporting = yield fetchGoalStatistics(urqlClient, selectedGoal.goalId);
        return {
            goals: filteredGoals,
            selectedGoal,
            goalsReportingData: goalsReporting,
            onboarding: false,
        };
    }
    catch (error) {
        throw new Error('Failed to fetch goals');
    }
});
