import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { iconButtonClasses, styled, Typography } from '@mui/material';
import { IconButton } from '@securecodewarrior/design-system-react/lib/legacy';
import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const Chip = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    border: `1px solid ${theme.palette.container.border.default}`,
    background: 'transparent',
    borderRadius: 2 * theme.shape.borderRadius,
    height: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    [`.${iconButtonClasses.root}`]: {
        color: theme.palette.clickable.default,
        padding: 0,
        margin: `0 ${theme.spacing(1)}`,
        fontSize: theme.typography.pxToRem(16),
        '&:hover': {
            color: theme.palette.clickable.hover,
            backgroundColor: 'transparent',
        },
        ':focus-visible': {
            outlineOffset: 0,
            outlineWidth: 1,
        },
    },
    '& .suffix': {
        marginLeft: theme.spacing(1),
    },
    '&.deletable': {
        paddingRight: theme.spacing(1),
        '& .suffix': {
            color: theme.palette.text.title,
            marginRight: theme.spacing(1),
        },
    },
}));
export const ChipWithClose = ({ onDelete, label, suffix }) => {
    const { t } = useTranslation();
    return (_jsx("div", { style: { display: 'inline-block' }, children: _jsxs(Chip, { className: onDelete ? 'deletable' : undefined, children: [_jsx(Typography, { variant: "caption", sx: { '&::first-letter': { textTransform: 'capitalize' } }, children: label }), _jsx(Typography, { className: "suffix", variant: "captionBold", children: suffix }), onDelete && (_jsx(IconButton, { sx: (theme) => ({ ':focus': { outline: `1px solid ${theme.palette.clickable.default}` } }), "aria-label": t('pages.explore.filters.clearFilter', { filters: `${label}${suffix}` }), onClick: onDelete, children: _jsx(CloseIcon, { fontSize: "small" }) }))] }) }));
};
