import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { visuallyHidden } from '@mui/utils';
import { Container, Skeleton } from '@securecodewarrior/design-system-react';
import { Text, NoSearchResultAlert, IconButton } from '@securecodewarrior/design-system-react/lib/legacy';
import { Checkbox, FormControlLabel, Button, Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { Box, FormControl, FormGroup, FormHelperText, FormLabel, Stack, Typography, capitalize, InputAdornment, debounce, Dialog, useTheme, } from '@mui/material';
import { SearchRounded as SearchIcon, CloseRounded as CloseIcon, FilterAltRounded as FilterIcon, GppGoodRounded as GppGoodIcon, CodeRounded as CodeIcon, LocalFireDepartmentRounded as LocalFireDepartmentIcon, } from '@mui/icons-material';
import { ContentCard, contentCardPropsFromContentItem } from './ContentCard';
import { isFilterStateEmpty } from './FilterState';
import { Trans, useTranslation } from 'react-i18next';
import { TextInput } from './TextInput';
import { Divider } from '../Divider';
import { ChipWithClose } from '../../common/components/atoms/Chip/Chip';
import { CategorySelection } from '../../common/components/organisms/CategorySelection/CategorySelection';
import { useExploreFilterStore } from './FilterContext';
import { LoginMessage } from './LoginMessage';
import { LanguageSelection } from '../../common/components/organisms/LanguageSelector/LanguageSelection';
import { usePlatformContext } from '../platformContext';
import { languageSelectionAnalytics } from '../../common/components/organisms/LanguageSelector/LanguageSelectionAnalytics';
import { MoreContentIcon } from './icons/MoreContentIcon';
const ITEM_INCREMENT = 300;
const ModalContext = createContext({
    setLanguageModalOpen: () => { },
    setCategoryModalOpen: () => { },
    isLanguageModalOpen: false,
    isCategoryModalOpen: false,
});
const limitItemCount = (items, hasLanguageFilters) => hasLanguageFilters ? ITEM_INCREMENT : items.length > ITEM_INCREMENT ? 18 : ITEM_INCREMENT;
export const ExploreView = (props) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const selectedCategories = useExploreFilterStore((store) => store.filterState.category);
    const selectedLanguages = useExploreFilterStore((store) => store.filterState.language);
    const selectedActivityTypes = useExploreFilterStore((store) => store.filterState.activityType);
    const selectedStatuses = useExploreFilterStore((store) => store.filterState.status);
    const searchTerm = useExploreFilterStore((store) => store.filterState.searchTerm);
    const filteredItems = useExploreFilterStore((store) => store.filteredItems);
    const doFilterAction = useExploreFilterStore((store) => store.doFilterAction);
    const [maxItems, setMaxItems] = useState(limitItemCount(filteredItems, selectedLanguages.length > 0));
    useEffect(() => {
        setMaxItems(limitItemCount(filteredItems, selectedLanguages.length > 0));
    }, [selectedLanguages, filteredItems]);
    const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
    const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
    const onReset = (target) => () => doFilterAction({ action: 'reset', target });
    return (_jsxs("main", { children: [_jsx(AccessibilityInformation, {}), _jsx(Container, { component: "div", sx: { paddingY: 10 }, children: _jsx(SearchInput, {}) }), _jsx(Divider, {}), _jsx(ModalContext.Provider, { value: { setLanguageModalOpen, setCategoryModalOpen, isLanguageModalOpen, isCategoryModalOpen }, children: _jsxs(Container, { component: "div", sx: { display: 'flex', gap: 7, paddingTop: 7, paddingBottom: 5 }, children: [_jsx(Filters, { filterOptions: props.filterOptions, onFilterRequested: doFilterAction, selectedLanguages: selectedLanguages, selectedCategories: selectedCategories, isAuthenticated: props.isAuthenticated, onLoginClicked: props.onLoginClicked }), _jsx(Typography, { component: "h2", sx: visuallyHidden, children: t('pages.explore.contentList') }), _jsxs(Stack, { gap: 5, flexGrow: 1, children: [_jsxs(Stack, { direction: "row", gap: 2, flexWrap: "wrap", minHeight: (theme) => theme.typography.pxToRem(24), alignItems: "center", children: [_jsx(ItemCount, { isAuthenticated: props.isAuthenticated }), searchTerm && (_jsx(ChipWithClose, { label: t('common.filtering.search.label'), suffix: searchTerm.length > 10 ? `${searchTerm.substring(0, 10)}...` : searchTerm, onDelete: onReset('searchTerm') })), _jsx(FilterChip, { label: t('pages.explore.filters.languages.title'), availableOptions: (_a = props.filterOptions.languages) !== null && _a !== void 0 ? _a : [], selectedOptions: selectedLanguages, onDelete: onReset('language') }), _jsx(FilterChip, { label: t('pages.explore.filters.categories.title'), availableOptions: Object.fromEntries(Object.values(props.filterOptions.categories).map((c) => {
                                                return [c.id, { label: c.name, id: c.id }];
                                            })), selectedOptions: Object.values(props.filterOptions.categories)
                                                .filter((c) => selectedCategories.includes(c.id) ||
                                                selectedCategories.includes(c.id.substring(0, c.id.lastIndexOf('.'))))
                                                .map((c) => c.id), onDelete: onReset('category') }), _jsx(FilterChip, { label: t('pages.explore.filters.activityTypes.title'), availableOptions: (_b = props.filterOptions.activityTypes) !== null && _b !== void 0 ? _b : [], selectedOptions: selectedActivityTypes, onDelete: onReset('activityType') }), _jsx(FilterChip, { label: capitalize(t('common.labels.status')), availableOptions: (_c = props.filterOptions.activityStatus) !== null && _c !== void 0 ? _c : [], selectedOptions: selectedStatuses, onDelete: onReset('status') })] }), !props.isAuthenticated && _jsx(LoginMessage, { onLoginClicked: props.onLoginClicked }), _jsx(NoSearchResults, { onReset: onReset('all') }), _jsxs(CssGridContainer, { children: [filteredItems.slice(0, maxItems).map((i) => (_jsx(ContentCard, Object.assign({}, contentCardPropsFromContentItem(i), { onClick: () => props.onActivitySelected(i) }), `${i.contentId}${'language' in i ? i.language.id : ''}`))), filteredItems.length > maxItems && (_jsx(ShowMoreBanner, { loadMoreActivities: () => setMaxItems((max) => max + ITEM_INCREMENT), unfilteredActivityCount: props.unfilteredActivityCount }))] })] })] }) })] }));
};
const SearchInput = () => {
    const { t } = useTranslation();
    const searchTerm = useExploreFilterStore((store) => store.filterState.searchTerm);
    const doFilterAction = useExploreFilterStore((store) => store.doFilterAction);
    const searchFieldRef = useRef(null);
    if (searchFieldRef.current && searchFieldRef.current.value !== searchTerm) {
        searchFieldRef.current.value = searchTerm;
    }
    const onSearchFieldTextChange = useMemo(() => {
        return debounce((e) => {
            doFilterAction({ action: 'search', value: e.target.value });
        }, 1000);
    }, [doFilterAction]);
    // this is only used for the initial search textfield value
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchInputProps = useMemo(() => ({ defaultValue: searchTerm }), []);
    return (_jsx(TextInput, { fullWidth: true, onChange: onSearchFieldTextChange, InputProps: {
            startAdornment: (_jsx(SearchIcon, { fontSize: "large", sx: (theme) => ({
                    mr: 2,
                    color: theme.palette.clickable.button.default,
                }) })),
            endAdornment: searchTerm ? (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": t('common.labels.clear'), onClick: () => {
                        doFilterAction({ action: 'search', value: '' });
                    }, sx: (theme) => ({
                        color: theme.palette.clickable.default,
                        padding: theme.spacing(1),
                        ':hover': { backgroundColor: theme.palette.container.fill.card2 },
                    }), children: _jsx(CloseIcon, {}) }) })) : undefined,
        }, inputProps: searchInputProps, inputRef: searchFieldRef, placeholder: t('pages.explore.filters.search.placeholder') }));
};
const ItemCount = (props) => {
    const count = useExploreFilterStore((store) => store.filteredItems.length);
    const isFiltered = useExploreFilterStore((store) => !isFilterStateEmpty(store.filterState));
    const tkey = !props.isAuthenticated ? 'public' : isFiltered ? 'filtered' : 'unfiltered';
    return (_jsx(Typography, { variant: "caption", role: "status", children: _jsx(Trans, { i18nKey: `pages.explore.activityCount.${tkey}`, values: { count }, children: _jsx(Typography, { variant: "captionBold" }) }) }));
};
const NoSearchResults = (props) => {
    const hasNoItems = useExploreFilterStore((store) => store.filteredItems.length === 0);
    return hasNoItems && _jsx(NoSearchResultAlert, { onReset: props.onReset });
};
export const FilterChip = (props) => {
    var _a;
    const { t } = useTranslation();
    return props.selectedOptions.length > 1 ? (_jsx(ChipWithClose, { label: props.label, suffix: `${props.selectedOptions.length}`, onDelete: props.onDelete })) : props.selectedOptions.length === 1 ? (_jsx(ChipWithClose, { label: props.label, suffix: t((_a = props.availableOptions[props.selectedOptions[0]]) === null || _a === void 0 ? void 0 : _a.label), onDelete: props.onDelete })) : undefined;
};
export const AccessibilityInformation = () => {
    const { t } = useTranslation();
    return (_jsxs(Box, { sx: visuallyHidden, children: [_jsx("h1", { children: t('pages.explore.title') }), _jsx("h2", { children: t('common.pages.about.accessibility') }), _jsx("p", { children: t('pages.explore.a11yInfo') })] }));
};
const cardTheme = (theme) => ({
    '.text-with-icon': {
        alignItems: 'center',
        display: 'flex',
        gap: 1,
    },
    '.card-container': Object.assign(Object.assign({ display: 'flex', flexDirection: 'column', flexGrow: 1, border: `1px solid ${theme.palette.container.border.default}`, borderRadius: 3, backgroundColor: theme.palette.container.fill.card1 }, theme.typography.body1), { '&:hover': {
            backgroundColor: theme.palette.container.fill.card2,
            cursor: 'pointer',
        }, '&:focus-within': {
            outline: `2px solid ${theme.palette.container.border.active}`,
        } }),
    '.content': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        padding: 5,
        gap: 5,
        '.content-text': {
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
        },
        '.card-title': Object.assign(Object.assign({ margin: 0 }, theme.typography.h5), { wordBreak: 'break-word', fontWeight: '600' }),
        '.badges': {
            display: 'flex',
            gap: 1.5,
            flexWrap: 'wrap',
            '.cl-badge': Object.assign(Object.assign({ display: 'flex', alignItems: 'center', paddingX: 2, paddingY: 1, borderRadius: 2 }, theme.typography.caption), { color: theme.palette.text.body, 'i, svg': {
                    minWidth: theme.typography.pxToRem(15),
                    fontSize: theme.typography.pxToRem(15),
                } }),
            '.default': {
                backgroundColor: theme.palette.container.fill.card2,
                border: `1px solid ${theme.palette.container.border.default}`,
            },
        },
    },
    '.card-info': {
        display: 'flex',
        color: theme.palette.text.soft,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.container.border.default}`,
        '> *:not(:first-of-type)': {
            borderLeft: `1px solid ${theme.palette.container.border.default}`,
            height: '100%',
        },
        '.activity-type-info': {
            paddingX: 5,
            paddingY: 3,
            svg: {
                fontSize: theme.typography.pxToRem(24),
            },
        },
        '.play-button': {
            color: theme.palette.badge.text,
            paddingX: 5,
            paddingY: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '.play-circle': {
                display: 'inline-flex',
                padding: 0.75,
                border: `1px solid ${theme.palette.badge.primary.border}`,
                borderRadius: '50%',
                backgroundColor: theme.palette.badge.primary.fill,
                '&.in_progress': {
                    backgroundColor: theme.palette.badge.inProgress.fill,
                    borderColor: theme.palette.badge.inProgress.border,
                },
                '&.completed': {
                    backgroundColor: theme.palette.badge.success.fill,
                    borderColor: theme.palette.badge.success.border,
                },
            },
            svg: {
                fontSize: theme.typography.pxToRem(16),
                flexShrink: 0,
            },
        },
    },
});
export const CssGridContainer = ({ children }) => (_jsx(Box, { display: "grid", columnGap: 5, rowGap: 5, gridAutoRows: "auto", gridTemplateColumns: {
        sm: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)',
    }, sx: cardTheme, children: children }));
const genSkeleton = (id) => Array.from(Array(5).keys()).map((x) => _jsx(Skeleton, { width: "100%" }, `skeleton_${x}_${id}`));
export const Filters = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const onChecked = props.onFilterRequested;
    const { t } = useTranslation();
    return (_jsxs(Stack, { minWidth: (theme) => theme.typography.pxToRem(260), maxWidth: (theme) => theme.typography.pxToRem(260), gap: 5, children: [_jsx(Typography, { component: "h2", sx: visuallyHidden, children: t('common.labels.filters') }), props.isAuthenticated ? (_jsx(LanguageFilterGroup, { languages: (_a = props.filterOptions) === null || _a === void 0 ? void 0 : _a.languages, selected: props.selectedLanguages, title: t('pages.explore.filters.languages.title'), helperText: _jsx(Trans, { i18nKey: "pages.explore.filters.languages.helperTextShort", values: { amount: Object.values((_c = (_b = props.filterOptions) === null || _b === void 0 ? void 0 : _b.categories) !== null && _c !== void 0 ? _c : {}).length }, components: { wrap: _jsx(Typography, { variant: "body0", sx: (theme) => ({ color: theme.palette.text.soft }) }) } }), onLanguagesSelected: (languages) => {
                    var _a;
                    return (_a = props.onFilterRequested) === null || _a === void 0 ? void 0 : _a.call(props, {
                        action: 'changeLanguages',
                        value: languages,
                    });
                } })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", component: "h3", children: t('pages.explore.filters.languages.title') }), _jsx(Typography, { variant: "body1", color: (theme) => theme.palette.text.soft, children: _jsx(Trans, { i18nKey: 'pages.explore.filters.languages.login', children: _jsx(Link, { onClick: props.onLoginClicked }) }) })] })), _jsx(Divider, {}), _jsx(CategoryFilterGroup, { categories: props.filterOptions ? Object.values(props.filterOptions.categories) : undefined, selected: props.selectedCategories, title: t('pages.explore.filters.categories.title'), helperText: _jsx(Trans, { i18nKey: "pages.explore.filters.categories.helperText", values: { amount: Object.values((_e = (_d = props.filterOptions) === null || _d === void 0 ? void 0 : _d.categories) !== null && _e !== void 0 ? _e : {}).length }, components: { wrap: _jsx(Typography, { variant: "body0", sx: (theme) => ({ color: theme.palette.text.soft }) }) } }), onCategoriesSelected: (categories) => {
                    var _a;
                    return (_a = props.onFilterRequested) === null || _a === void 0 ? void 0 : _a.call(props, {
                        action: 'changeCategories',
                        value: categories,
                    });
                } }), _jsx(Divider, {}), _jsx(FilterGroup, { filterGroupType: "activityType", title: t('pages.explore.filters.activityTypes.title'), items: (_f = props.filterOptions) === null || _f === void 0 ? void 0 : _f.activityTypes, onChecked: onChecked, isAuthenticated: props.isAuthenticated }), !props.isAuthenticated ? (_jsx(Typography, { variant: "body1", color: (theme) => theme.palette.text.soft, children: _jsx(Trans, { i18nKey: 'pages.explore.filters.activityTypes.login', children: _jsx(Link, { onClick: props.onLoginClicked }) }) })) : (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(FilterGroup, { filterGroupType: "status", title: capitalize(t('common.labels.status')), items: (_g = props.filterOptions) === null || _g === void 0 ? void 0 : _g.activityStatus, onChecked: onChecked, isAuthenticated: props.isAuthenticated })] }))] }));
};
const LanguageFilterGroup = ({ languages, selected, title, helperText, onLanguagesSelected, }) => {
    const modalContext = useContext(ModalContext);
    const { t } = useTranslation();
    const theme = useTheme();
    const { logAnalyticsEvent } = usePlatformContext();
    const languageAnalytics = useMemo(() => languageSelectionAnalytics('explore', logAnalyticsEvent), [logAnalyticsEvent]);
    const languageGroups = {};
    if (languages) {
        Object.entries(languages).forEach(([_id, info]) => {
            var _a;
            if (info.group === undefined)
                return;
            const group = (languageGroups[info.group] = (_a = languageGroups[info.group]) !== null && _a !== void 0 ? _a : []);
            group.push(info);
        });
    }
    const onSubmit = (selection) => {
        modalContext.setLanguageModalOpen(false);
        onLanguagesSelected(selection);
    };
    return (_jsxs(FormControl, { component: "fieldset", children: [_jsx(FormLabel, { sx: { mb: 0 }, children: _jsx(Typography, { variant: "h6", component: "h3", children: title }) }), selected && selected.length === 0 && (_jsx(FormHelperText, { component: "div", sx: { mx: 0, mt: 4 }, children: _jsx(Typography, { color: (theme) => theme.palette.text.soft, children: helperText }) })), _jsx(Stack, { gap: 2, mt: (selected === null || selected === void 0 ? void 0 : selected.length) !== 0 ? 4 : 0, children: selected && languages
                    ? selected.map((x, index) => {
                        var _a, _b, _c;
                        return index > 4 ? null : (_jsx(Text, { typographyProps: { color: theme.palette.text.soft }, label: index === 4 && selected.length > 5 ? `+${selected.length - 4}` : (_b = (_a = languages[x]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '', startIcon: _jsx("i", { style: { color: theme.palette.text.soft }, className: index < 4 ? (_c = languages[x]) === null || _c === void 0 ? void 0 : _c.iconClassName : 'devicon-devicon-plain' }) }, `selected_language_${x}`));
                    })
                    : genSkeleton('selected_languages') }), _jsx(Box, { mt: 5, children: selected && languages ? (_jsx(Button, { onClick: () => modalContext.setLanguageModalOpen(true), variant: "text", startIcon: _jsx(FilterIcon, {}), children: selected.length === 0
                        ? t('components.organisms.languageSelection.filter.select')
                        : t('components.organisms.languageSelection.filter.change') })) : (_jsx(Skeleton, { height: "30px", width: "135px", variant: "rounded" })) }), selected && languages && (_jsx(Dialog, { open: modalContext.isLanguageModalOpen, fullWidth: true, maxWidth: 'xl', scroll: 'body', onClose: () => {
                    modalContext.setLanguageModalOpen(false);
                    languageAnalytics.onCancelled();
                }, sx: (theme) => ({
                    '& .MuiPaper-root:not(.MuiAlert-root)': {
                        border: `1px solid ${theme.palette.container.border.default}`,
                        backgroundColor: theme.palette.container.fill.card1,
                        backgroundImage: 'unset',
                        borderRadius: 3,
                    },
                }), children: _jsx(LanguageSelection, { required: false, titleKey: 'pages.explore.filters.languages.title', subtitleKey: 'pages.explore.filters.languages.helperTextFull', searchPlaceholderKey: 'pages.explore.filters.languages.searchPlaceholder', collections: languageGroups, initiallySelectedLanguages: selected, onSearchUsed: languageAnalytics.onSearchUsed, onCategoryToggled: languageAnalytics.onCategoryToggled, onSearchReset: languageAnalytics.onSearchReset, onBack: {
                        labelKey: 'common.labels.cancel',
                        action: () => {
                            modalContext.setLanguageModalOpen(false);
                            languageAnalytics.onCancelled();
                        },
                    }, onSubmit: {
                        labelKey: 'pages.explore.filters.languages.save',
                        action: (selected) => {
                            onSubmit(selected);
                            languageAnalytics.onSubmit(selected);
                        },
                    }, changesRequired: false }) }))] }));
};
const CategoryFilterGroup = ({ categories, selected, title, helperText, onCategoriesSelected, }) => {
    const modalContext = useContext(ModalContext);
    const { t } = useTranslation();
    const theme = useTheme();
    const onSubmit = (selection) => {
        modalContext.setCategoryModalOpen(false);
        onCategoriesSelected(selection);
    };
    const selectedCategories = selected && categories
        ? categories.filter((c) => {
            return selected.includes(c.id) || selected.includes(c.id.substring(0, c.id.lastIndexOf('.')));
        })
        : undefined;
    return (_jsxs(FormControl, { component: "fieldset", children: [_jsx(FormLabel, { sx: { mb: 0 }, children: _jsx(Typography, { variant: "h6", component: "h3", children: title }) }), helperText && (_jsx(FormHelperText, { component: "div", sx: { mx: 0, mt: 4 }, children: _jsx(Typography, { color: (theme) => theme.palette.text.soft, children: helperText }) })), _jsx(Stack, { gap: 2, mt: (selected === null || selected === void 0 ? void 0 : selected.length) !== 0 ? 4 : 0, children: selectedCategories
                    ? selectedCategories.map((x, index) => index > 4 ? null : (_jsx(Text, { typographyProps: { color: theme.palette.text.soft }, label: index === 4 && selectedCategories.length > 5 ? `+${selectedCategories.length - 4}` : x.name, startIcon: _jsx(GppGoodIcon, { sx: { color: theme.palette.text.soft } }) }, `selected_category_${x.id}`)))
                    : genSkeleton('selected_categories') }), _jsx(Box, { mt: 5, children: selected && categories ? (_jsx(Button, { onClick: () => modalContext.setCategoryModalOpen(true), variant: "text", startIcon: _jsx(FilterIcon, {}), children: selected.length === 0
                        ? t('components.organisms.categorySelection.filter.select')
                        : t('components.organisms.categorySelection.filter.change') })) : (_jsx(Skeleton, { height: "30px", width: "135px", variant: "rounded" })) }), selected && categories && (_jsx(Dialog, { open: modalContext.isCategoryModalOpen, fullWidth: true, maxWidth: 'xl', scroll: 'body', onClose: () => modalContext.setCategoryModalOpen(false), sx: (theme) => ({
                    '& .MuiPaper-root:not(.MuiAlert-root)': {
                        border: `1px solid ${theme.palette.container.border.default}`,
                        borderRadius: 3,
                    },
                }), children: _jsx(CategorySelection, { categories: categories, initiallySelectedCategories: selected, onSubmit: {
                        action: onSubmit,
                        label: t('components.organisms.categorySelection.filter.apply'),
                    }, onBack: {
                        action: () => modalContext.setCategoryModalOpen(false),
                        label: t('common.labels.cancel'),
                    } }) }))] }));
};
const FilterGroup = ({ title, helperText, items, filterGroupType, isAuthenticated, }) => {
    return (_jsxs(FormControl, { component: "fieldset", variant: "outlined", sx: (theme) => ({ gap: theme.spacing(5) }), children: [_jsx(FormLabel, { sx: { mb: 0 }, children: _jsx(Typography, { variant: "h6", component: "h3", children: title }) }), helperText && (_jsx(FormHelperText, { component: "div", sx: { m: 0 }, children: _jsx(Typography, { color: (theme) => theme.palette.text.soft, children: helperText }) })), _jsxs(FormGroup, { sx: (theme) => ({
                    gap: theme.spacing(2),
                }), children: [Object.entries(items !== null && items !== void 0 ? items : {}).map(([id, x], i) => (_jsx(Filter, { item: x, type: filterGroupType, id: id, isAuthenticated: isAuthenticated }, `${id}_${i}`))), items === undefined && genSkeleton(filterGroupType)] })] }));
};
const Filter = React.forwardRef(function Filter({ item, type, id, isAuthenticated, }, ref) {
    const { t } = useTranslation();
    const checked = useExploreFilterStore((store) => store.filterState[type].includes(id)) || !isAuthenticated;
    const doFilterAction = useExploreFilterStore((store) => store.doFilterAction);
    const label = t(item.label);
    const onChange = (e) => {
        const action = e.currentTarget.checked ? 'add' : 'delete';
        doFilterAction({ action, type, value: id });
    };
    return (_jsx(FormControlLabel, { value: id, disabled: !isAuthenticated, label: _jsx(Text, { startIcon: item.icon, label: label, typographyProps: { variant: 'body1', color: 'inherit' } }), control: _jsx(Checkbox, { checked: checked, onChange: onChange, value: id, name: id }), disableTypography: true, ref: ref }));
});
const toFloor = (value, floor) => value - (value % floor);
const highestPossibleFloor = (value) => {
    return value > 1000 ? `${toFloor(value, 1000)}+` : value > 100 ? `${toFloor(value, 100)}+` : value.toString();
};
const ShowMoreBanner = (props) => {
    const modalContext = useContext(ModalContext);
    const { t } = useTranslation();
    const hasLanguageSelected = useExploreFilterStore((store) => store.filterState.language.length > 0);
    const hasCategorySelected = useExploreFilterStore((store) => store.filterState.category.length > 0);
    return (_jsxs(Box, { sx: (theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            p: 5,
            borderRadius: 3,
            backgroundColor: theme.palette.container.fill.card1,
            border: `1px solid ${theme.palette.container.border.default}`,
            gridColumn: '1 / -1', // span the entire row
            flexDirection: {
                xs: 'column',
                md: 'row',
            },
        }), children: [_jsx(MoreContentIcon, { sx: { height: { xs: '100px', lg: '158px' }, width: 'auto' } }), _jsxs(Box, { sx: { display: 'flex', gap: 2, flexDirection: 'column' }, children: [_jsx(Typography, { variant: "h3", sx: (theme) => ({ color: theme.palette.text.soft }), children: _jsx(Trans, { i18nKey: "pages.explore.showMore.title", components: [_jsx(Box, { component: "span", sx: (theme) => ({ color: theme.palette.text.title }) })] }) }), _jsx(Typography, { variant: "subtitle1", component: "p", color: (theme) => theme.palette.text.title, children: t(!hasLanguageSelected
                            ? 'pages.explore.showMore.descriptionFilterLanguages'
                            : !hasCategorySelected
                                ? 'pages.explore.showMore.descriptionFilterLanguagesCategories'
                                : 'pages.explore.showMore.descriptionChangeLanguagesCategories', {
                            amount: highestPossibleFloor(props.unfilteredActivityCount),
                        }) }), _jsxs(Box, { sx: { display: 'flex', gap: 3, pt: 2 }, children: [!hasLanguageSelected ? (_jsx(Button, { variant: "contained", startIcon: _jsx(CodeIcon, {}), onClick: () => modalContext.setLanguageModalOpen(true), children: t('pages.explore.showMore.selectLanguages') })) : (_jsx(Button, { variant: "contained", startIcon: _jsx(FilterIcon, {}), onClick: () => modalContext.setCategoryModalOpen(true), children: t(hasCategorySelected
                                    ? 'pages.explore.showMore.editCategories'
                                    : 'pages.explore.showMore.selectCategories') })), _jsx(Button, { startIcon: _jsx(LocalFireDepartmentIcon, {}), onClick: props.loadMoreActivities, children: t('pages.explore.showMore.loadMore', { count: ITEM_INCREMENT }) })] })] })] }));
};
