import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Dialog, Stack, Typography, useTheme } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Chip } from '../../atoms/Chip';
import { QuestEditorSection, QuestEditorSubsection } from './QuestEditorSection';
import { CategorySelection } from '../CategorySelection';
import { useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../common/Tooltip';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { usePlatformContext } from '../../../../platformContext';
import { questDetailsAnalytics } from '../../../analytics/QuestDetails.analytics';
import { getEditingDisabledTooltip } from '../../../utils';
import { RemoveTextButton } from '../../atoms/TextButton';
import { match } from 'ts-pattern';
const defaultObjectiveTypeOrder = [
    { kind: 'most-common-vulnerabilities' },
    { kind: 'learning-outcome' },
];
export const QuestEditorObjectivesSection = ({ selectedCategories, availableCategories, status, skeletonMode, editingDisabled, mostCommonVulnerabilitiesSelected, mostCommonVulnerabilitiesEnabled, onSelectionEdit, onMostCommonVulnerabilitiesUpdate, }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const objectiveTypeOrder = useRef([...defaultObjectiveTypeOrder]);
    const readOnly = !!skeletonMode || editingDisabled;
    const readOnlyTooltip = getEditingDisabledTooltip(editingDisabled, status, t, i18n.format, skeletonMode);
    const someCustomVulnerabilitySelected = selectedCategories.length > 0;
    const showAddCustomVulnerabilitiesButton = (!someCustomVulnerabilitySelected && !skeletonMode) || skeletonMode === 'create';
    const showAddMostCommonVulnerabilitiesButton = mostCommonVulnerabilitiesEnabled &&
        ((!mostCommonVulnerabilitiesSelected && !skeletonMode) || skeletonMode === 'create');
    const someButtonVisible = showAddCustomVulnerabilitiesButton || showAddMostCommonVulnerabilitiesButton;
    const someObjectiveSelected = mostCommonVulnerabilitiesSelected || someCustomVulnerabilitySelected;
    const handleOpenVulnerabilityDialog = () => {
        setDialogOpen(true);
        analytics.onVulnerabilityDialogOpened(status, (selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.length) || 0);
    };
    const handleVulnerabilityDialogClosing = () => {
        setDialogOpen(false);
        analytics.onVulnerabilityDialogClosed(status);
    };
    const handleCustomVulnerabilitySelection = (updatedCategories) => {
        if (!selectedCategories.length && updatedCategories.length) {
            pushItemToTopOfOrder({ kind: 'learning-outcome' });
        }
        setDialogOpen(false);
        analytics.onObjectiveVulnerabilitiesUpdated(status, selectedCategories, updatedCategories);
        onSelectionEdit === null || onSelectionEdit === void 0 ? void 0 : onSelectionEdit(updatedCategories);
    };
    const pushItemToTopOfOrder = (topItem) => {
        objectiveTypeOrder.current = objectiveTypeOrder.current.filter((item) => item.kind !== topItem.kind);
        objectiveTypeOrder.current.push(topItem);
    };
    const handleToggleMostCommonVulnerabilities = () => {
        const isSelected = !mostCommonVulnerabilitiesSelected;
        analytics.onMostCommonVulnerabilitiesToggled(status, isSelected);
        if (isSelected) {
            pushItemToTopOfOrder({ kind: 'most-common-vulnerabilities' });
        }
        onMostCommonVulnerabilitiesUpdate === null || onMostCommonVulnerabilitiesUpdate === void 0 ? void 0 : onMostCommonVulnerabilitiesUpdate(isSelected);
    };
    return (_jsxs(_Fragment, { children: [_jsx(QuestEditorSection, { children: _jsxs(Box, { sx: { flex: 1 }, children: [_jsx(Typography, { component: "h2", variant: "h5", sx: (theme) => ({ lineHeight: theme.typography.pxToRem(18) }), children: t('questsAdmin.components.organisms.questEditor.sections.objectives.heading') }), _jsx(Typography, { variant: "body1", sx: (theme) => ({ color: theme.palette.text.body, marginTop: theme.spacing(2) }), children: _jsx(Trans, { i18nKey: "questsAdmin.components.organisms.questEditor.sections.objectives.subheader" }) }), _jsx(Stack, { rowGap: 4, marginTop: 9, marginBottom: someButtonVisible && someObjectiveSelected ? 10 : 0, children: _jsxs(_Fragment, { children: [objectiveTypeOrder.current.map((item) => match(item)
                                        .with({ kind: 'most-common-vulnerabilities' }, () => mostCommonVulnerabilitiesSelected && mostCommonVulnerabilitiesEnabled ? (_jsx(MostCommonVulnerabilitiesSection, { editingDisabled: editingDisabled, handleToggleMostCommonVulnerabilities: handleToggleMostCommonVulnerabilities })) : null)
                                        .with({ kind: 'learning-outcome' }, () => someCustomVulnerabilitySelected ? (_jsx(CustomVulnerabilitiesSection, { selectedCategories: selectedCategories, status: status, editingDisabled: editingDisabled, readOnly: readOnly, readOnlyTooltip: readOnlyTooltip, availableCategories: availableCategories, handleOpenVulnerabilityDialog: handleOpenVulnerabilityDialog, onSelectionEdit: onSelectionEdit })) : null)
                                        .exhaustive()), skeletonMode === 'edit' && _jsx(Skeleton, { height: "96px", variant: "rounded", sx: { width: '100%' } })] }) }), someButtonVisible && (_jsxs(Stack, { direction: "row", columnGap: 2.5, children: [showAddMostCommonVulnerabilitiesButton && (_jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { sx: { height: 'fit-content' }, children: _jsx(Button, { sx: { height: theme.spacing(9) }, startIcon: _jsx(AddIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(18) }) }), disabled: readOnly, onClick: handleToggleMostCommonVulnerabilities, children: t('questsAdmin.components.organisms.questEditor.sections.objectives.subsections.mostCommonVulns.heading') }) }) })), showAddCustomVulnerabilitiesButton && (_jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { sx: { height: 'fit-content' }, children: _jsx(Button, { sx: { height: theme.spacing(9) }, startIcon: _jsx(AddIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(18) }) }), disabled: readOnly, onClick: handleOpenVulnerabilityDialog, children: t('questsAdmin.components.organisms.questEditor.sections.objectives.subsections.vulnerabilitiesAndConcepts.customVulns') }) }) }))] }))] }) }), _jsx(Dialog, { open: dialogOpen, fullWidth: true, maxWidth: "xl", scroll: "body", onClose: handleVulnerabilityDialogClosing, sx: (theme) => ({
                    '& .MuiPaper-root:not(.MuiAlert-root)': {
                        border: `1px solid ${theme.palette.container.border.default}`,
                        borderRadius: 3,
                        '@media (max-height: 890px)': {
                            marginTop: theme.spacing(5),
                            marginBottom: theme.spacing(5),
                        },
                        '@media (max-height: 784px)': {
                            marginTop: theme.spacing(3),
                            marginBottom: theme.spacing(3),
                        },
                    },
                }), children: _jsx(CategorySelection, { categories: availableCategories, initiallySelectedCategories: selectedCategories, questStatus: status, onSubmit: { action: handleCustomVulnerabilitySelection }, onBack: {
                        action: handleVulnerabilityDialogClosing,
                        label: t('common.labels.cancel'),
                    } }) })] }));
};
const CustomVulnerabilitiesSection = ({ selectedCategories, status, editingDisabled, readOnly, readOnlyTooltip, availableCategories, handleOpenVulnerabilityDialog, onSelectionEdit, }) => {
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const categoryMap = useMemo(() => Object.fromEntries(Object.values(availableCategories).map((item) => [item.id, item])), [availableCategories]);
    const handleClearCustomVulnerabilities = () => {
        analytics.onObjectiveVulnerabilitiesUpdated(status, selectedCategories, []);
        onSelectionEdit === null || onSelectionEdit === void 0 ? void 0 : onSelectionEdit([]);
    };
    return (_jsxs(QuestEditorSubsection, { children: [_jsxs(Box, { sx: (theme) => ({
                    boxSizing: 'border-box',
                    width: { xs: '100%', lg: theme.typography.pxToRem(300) },
                }), children: [_jsx(Typography, { component: "h3", variant: "h6", sx: (theme) => ({ marginBottom: theme.spacing(2.5), lineHeight: theme.typography.pxToRem(18) }), children: t('questsAdmin.components.organisms.questEditor.sections.objectives.subsections.vulnerabilitiesAndConcepts.customVulns') }), !editingDisabled && _jsx(RemoveTextButton, { onClick: handleClearCustomVulnerabilities, label: "common.remove" })] }), _jsxs(Box, { sx: (theme) => ({
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: theme.spacing(2),
                    flex: 1,
                    boxSizing: 'border-box',
                }), children: [selectedCategories
                        .sort((a, b) => { var _a, _b; return (((_a = categoryMap[a]) === null || _a === void 0 ? void 0 : _a.name) || a).localeCompare(((_b = categoryMap[b]) === null || _b === void 0 ? void 0 : _b.name) || b); })
                        .map((cat) => {
                        var _a;
                        return (_jsx(Chip, { label: ((_a = categoryMap[cat]) === null || _a === void 0 ? void 0 : _a.name) || cat }, cat));
                    }), _jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { sx: { height: 'fit-content' }, children: _jsx(Button, { sx: (theme) => ({ height: theme.spacing(9) }), startIcon: _jsx(EditIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(18) }) }), disabled: readOnly, onClick: handleOpenVulnerabilityDialog, children: t('questsAdmin.components.organisms.questEditor.sections.objectives.subsections.vulnerabilitiesAndConcepts.editButton') }) }) })] })] }));
};
const MostCommonVulnerabilitiesSection = ({ editingDisabled, handleToggleMostCommonVulnerabilities, }) => (_jsxs(QuestEditorSubsection, { children: [_jsxs(Box, { sx: (theme) => ({
                boxSizing: 'border-box',
                width: { xs: '100%', lg: theme.typography.pxToRem(300) },
            }), children: [_jsx(Typography, { component: "h3", variant: "h6", sx: (theme) => ({ marginBottom: theme.spacing(2.5), lineHeight: theme.typography.pxToRem(18) }), children: _jsx(Trans, { i18nKey: "questsAdmin.components.organisms.questEditor.sections.objectives.subsections.mostCommonVulns.heading" }) }), !editingDisabled && _jsx(RemoveTextButton, { onClick: handleToggleMostCommonVulnerabilities, label: "common.remove" })] }), _jsxs(Stack, { rowGap: 6, sx: { flex: 1 }, children: [_jsx(Typography, { variant: "body1", sx: (theme) => ({ color: theme.palette.text.soft, maxWidth: theme.typography.pxToRem(760) }), children: _jsx(Trans, { i18nKey: "questsAdmin.components.organisms.questEditor.sections.objectives.subsections.mostCommonVulns.descriptionPartOne" }) }), _jsx(Typography, { variant: "body1", sx: (theme) => ({ color: theme.palette.text.soft, maxWidth: theme.typography.pxToRem(760) }), children: _jsx(Trans, { i18nKey: "questsAdmin.components.organisms.questEditor.sections.objectives.subsections.mostCommonVulns.descriptionPartTwo" }) })] })] }));
