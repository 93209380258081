import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BookmarkRounded, MenuBookRounded, ShuffleRounded, TimelapseRounded } from '@mui/icons-material';
import { Text } from '@securecodewarrior/design-system-react/lib/legacy';
import { CourseStatus } from './components/CourseStatus';
import { getLanguageInformation } from '../../utils/mapper';
import { usePlatformContext } from '../../platformContext';
import { Box, ButtonBase, Stack, Typography, typographyClasses } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
export const LearnPageViewList = ({ courses, onCourseClicked }) => {
    const metadata = usePlatformContext().metadata;
    const { t } = useTranslation();
    const onCardClicked = (id) => {
        var _a;
        const selection = (_a = window === null || window === void 0 ? void 0 : window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString();
        if (selection)
            return;
        onCourseClicked(id);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Text, { typographyProps: {
                    variant: 'h5',
                    component: 'h2',
                    sx: (theme) => ({
                        b: {
                            color: theme.palette.text.soft,
                        },
                    }),
                }, startIcon: _jsx(MenuBookRounded, { sx: (theme) => ({ color: theme.palette.text.soft }) }), label: _jsx(Trans, { i18nKey: "pages.learn.overview.courseList.courses", count: courses.length }) }), _jsx(Stack, { gap: 5, role: "list", "aria-label": t('common.courses'), mt: 9, children: courses.map((course) => (_jsxs(Box, { role: "listitem", onClick: () => onCardClicked(course.frameworkLanguage), sx: (theme) => ({
                        cursor: 'pointer',
                        display: 'flex',
                        [`&:has(.${courseListClasses.titleButton}:focus-visible)`]: {
                            outline: `2px solid ${theme.palette.clickable.button.default}`,
                            borderRadius: theme.shape.border.radius.md,
                        },
                        [`&:hover .${courseListClasses.cardDetails}`]: {
                            backgroundColor: theme.palette.container.fill.card2,
                        },
                    }), children: [_jsx(CourseStatus, { language: getLanguageInformation(course.frameworkLanguage, metadata), minutesLeft: course.minutesLeftToComplete, progressInPercent: ((course.minutesToComplete - course.minutesLeftToComplete) / course.minutesToComplete) * 100, sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }), _jsxs(Stack, { className: courseListClasses.cardDetails, sx: (theme) => ({
                                flexGrow: 1,
                                alignItems: 'start',
                                border: `1px solid ${theme.palette.container.border.default}`,
                                borderLeft: 'unset',
                                borderTopRightRadius: theme.shape.border.radius.md,
                                borderBottomRightRadius: theme.shape.border.radius.md,
                                backgroundColor: theme.palette.container.fill.card1,
                                p: 7,
                                gap: 5,
                            }), children: [_jsx(Typography, { variant: "h3", children: _jsx(ButtonBase, { className: courseListClasses.titleButton, sx: (theme) => (Object.assign({ textAlign: 'unset', pointerEvents: 'unset', userSelect: 'text' }, theme.typography.h3)), children: getLanguageInformation(course.frameworkLanguage, metadata).label }) }), _jsxs(Stack, { sx: (theme) => ({
                                        gap: 3,
                                        [`.${typographyClasses.root}`]: Object.assign(Object.assign({}, theme.typography.caption), { color: theme.palette.text.body }),
                                        svg: { color: theme.palette.text.soft },
                                    }), children: [_jsx(Text, { label: _jsx(Trans, { i18nKey: "pages.learn.overview.courseList.chapters", count: course.numChapters }), startIcon: _jsx(MenuBookRounded, {}) }), _jsx(Text, { label: _jsx(Trans, { i18nKey: "pages.learn.overview.courseList.topics", count: course.numTopics }), startIcon: _jsx(BookmarkRounded, {}) }), _jsx(Text, { label: getTimeElement(course.minutesToComplete), startIcon: _jsx(TimelapseRounded, {}) }), course.backupLanguageUsed && (_jsx(Text, { label: _jsx(Trans, { i18nKey: "pages.learn.overview.courseList.backupLanguageLabel" }), startIcon: _jsx(ShuffleRounded, {}) }))] })] })] }, course.frameworkLanguage))) })] }));
};
export const getTimeElement = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return (_jsxs(Stack, { direction: "row", gap: 2, component: "span", children: [hours > 0 && (_jsx(Stack, { direction: "row", gap: 1, component: "span", children: _jsx(Trans, { i18nKey: "common.datetime.hours", count: hours }) })), minutesLeft > 0 && (_jsx(Stack, { direction: "row", gap: 1, component: "span", children: _jsx(Trans, { i18nKey: "common.datetime.minutes", count: minutesLeft }) }))] }));
};
const cssPrefix = 'scw-courseList';
const courseListClasses = {
    cardDetails: `${cssPrefix}-card-details`,
    titleButton: `${cssPrefix}-title-button`,
};
