import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography, useTheme } from '@mui/material';
import { QuestEditorSection, QuestEditorSubsection } from './QuestEditorSection';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '../../molecules/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Tooltip } from '../../../../common/Tooltip';
import { getEditingDisabledTooltip, getMuiDatePickerLanguageKey } from '../../../utils';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { usePlatformContext } from '../../../../platformContext';
import { useMemo } from 'react';
import { questDetailsAnalytics } from '../../../analytics/QuestDetails.analytics';
export const QuestEditorOtherSettingsSection = ({ startDate, endDate, status, validationErrors, skeletonMode, editingDisabled, onStartDateEdit, onEndDateEdit,
// eslint-disable-next-line sonarjs/cognitive-complexity
 }) => {
    var _a, _b, _c, _d;
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const readOnly = !!skeletonMode || editingDisabled;
    const readOnlyTooltip = getEditingDisabledTooltip(editingDisabled, status, t, i18n.format);
    const startDateErrorMessage = ((_a = validationErrors.find((e) => e.field === 'startDate')) === null || _a === void 0 ? void 0 : _a.message) || '';
    const endDateErrorMessage = ((_b = validationErrors.find((e) => e.field === 'endDate')) === null || _b === void 0 ? void 0 : _b.message) || '';
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const adapterLocale = useMemo(() => getMuiDatePickerLanguageKey(i18n.language).toLowerCase(), [i18n.language]);
    const dateNow = new Date();
    const endDateMinDate = ((_c = startDate === null || startDate === void 0 ? void 0 : startDate.getTime) === null || _c === void 0 ? void 0 : _c.call(startDate)) && startDate.getTime() > dateNow.getTime() ? dayjs(startDate) : dayjs(dateNow);
    return (_jsx(_Fragment, { children: _jsx(QuestEditorSection, { children: _jsxs(Box, { sx: { flex: 1 }, children: [_jsx(Typography, { component: "h2", variant: "h5", sx: {
                            marginBottom: theme.spacing(10),
                            lineHeight: theme.typography.pxToRem(18),
                        }, children: t('questsAdmin.components.organisms.questEditor.sections.otherSettings.heading') }), _jsxs(QuestEditorSubsection, { children: [_jsxs(Box, { sx: {
                                    boxSizing: 'border-box',
                                    width: { xs: '100%', md: theme.typography.pxToRem(300) },
                                }, children: [_jsx(Typography, { component: "h3", variant: "h6", sx: {
                                            marginBottom: theme.spacing(4),
                                            lineHeight: theme.typography.pxToRem(18),
                                        }, children: t('questsAdmin.components.organisms.questEditor.sections.otherSettings.subsections.duration.heading') }), _jsx(Typography, { component: "p", variant: "subtitle1", maxWidth: (theme) => theme.typography.pxToRem(300), fontSize: (theme) => theme.typography.pxToRem(14), lineHeight: (theme) => theme.typography.pxToRem(20), children: t('questsAdmin.components.organisms.questEditor.sections.otherSettings.subsections.duration.text') })] }), _jsxs(Box, { sx: {
                                    flex: 1,
                                    boxSizing: 'border-box',
                                }, children: [_jsx(Box, { hidden: skeletonMode === 'edit', sx: {
                                            display: skeletonMode === 'edit' ? 'none' : 'flex',
                                            flexDirection: 'column',
                                            width: { s: '100%', md: theme.typography.pxToRem(530) },
                                            gap: theme.spacing(2),
                                            '& .MuiInputBase-input[type="text"]:not(.SearchInput-input):disabled': {
                                                // global style in portal-frontend that's causing issues
                                                backgroundColor: 'transparent',
                                            },
                                        }, children: _jsxs(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: adapterLocale, children: [_jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { children: _jsx(DatePicker, { sx: { width: '100%' }, label: t('questsAdmin.components.organisms.questEditor.sections.otherSettings.subsections.duration.startDateInputLabel'), maxDate: ((_d = endDate === null || endDate === void 0 ? void 0 : endDate.getTime) === null || _d === void 0 ? void 0 : _d.call(endDate)) ? dayjs(endDate) : undefined, value: dayjs(startDate), onChange: (newValue) => {
                                                                var _a;
                                                                if (newValue) {
                                                                    analytics.onStartDateUpdated(status, (_a = newValue.toDate) === null || _a === void 0 ? void 0 : _a.call(newValue), endDate);
                                                                    onStartDateEdit === null || onStartDateEdit === void 0 ? void 0 : onStartDateEdit(newValue.startOf('day').toDate());
                                                                }
                                                                else {
                                                                    onStartDateEdit === null || onStartDateEdit === void 0 ? void 0 : onStartDateEdit(null);
                                                                }
                                                            }, errorMessage: t(startDateErrorMessage), disabled: readOnly }) }) }), _jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { children: _jsx(DatePicker, { sx: { width: '100%' }, label: t('questsAdmin.components.organisms.questEditor.sections.otherSettings.subsections.duration.endDateInputLabel'), minDate: endDateMinDate, value: dayjs(endDate), onChange: (newValue) => {
                                                                var _a;
                                                                if (newValue) {
                                                                    analytics.onEndDateUpdated(status, startDate, (_a = newValue.toDate) === null || _a === void 0 ? void 0 : _a.call(newValue));
                                                                    onEndDateEdit === null || onEndDateEdit === void 0 ? void 0 : onEndDateEdit(newValue.endOf('day').toDate());
                                                                }
                                                                else {
                                                                    onEndDateEdit === null || onEndDateEdit === void 0 ? void 0 : onEndDateEdit(null);
                                                                }
                                                            }, errorMessage: t(endDateErrorMessage), disabled: readOnly }) }) })] }) }), skeletonMode === 'edit' && (_jsxs(Box, { sx: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: { s: '100%', md: theme.typography.pxToRem(530) },
                                            gap: theme.spacing(2),
                                        }, children: [_jsx(Skeleton, { variant: "rounded", height: "36px", width: "100%" }), _jsx(Skeleton, { variant: "rounded", height: "36px", width: "100%" })] }))] })] })] }) }) }));
};
