import angular from 'angular';

import { ANALYTICS_EVENTS } from './constants';
import MODULE from './module';

const app = angular.module(MODULE);

app.constant('AnalyticsEvents', ANALYTICS_EVENTS);

app.service('AnalyticsService', [
  '$log',
  '$window',
  '$location',
  function ($log, $window, $location) {
    function amplitudeInstance() {
      if ($window?.amplitude) {
        return $window.amplitude;
      }
      $log.error('No amplitude instance');
      return null;
    }

    function getRouteProps() {
      const absFullUrl = $location.absUrl();
      const absUrl = absFullUrl.split('?')[0];
      const sanitisedUrl = absUrl
        .replace(/\/[0-9a-f]{24}/gi, '/*')
        .replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi, '/*');
      const [host, path] = sanitisedUrl.split('/#');
      return {
        url: sanitisedUrl,
        type: 'page',
        host: host,
        path: `/#${path || ''}`,
      };
    }

    return {
      logEvent: async function (event, eventProps) {
        const amplitude = amplitudeInstance();
        if (!amplitude) {
          return;
        }

        const params = new Proxy(new URLSearchParams($window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });
        const { tenant_id, integration_id } = params;
        const channel = window.localStorage.getItem('channel');

        await amplitude.track(event, {
          ...this.getDefaultEventProps(),
          ...(eventProps && eventProps),
          ...(tenant_id && { tenant_id }),
          ...(integration_id && { integration_id }),
          ...(channel && { channel }),
        });
      },
      logPageViewEvent: function (_absUrl, pageName = '', type = 'page') {
        if (pageName !== 'home' && pageName !== 'dashboard') {
          this.logEvent(ANALYTICS_EVENTS.General.VIEW_CHANGE, {
            ...getRouteProps(),
            type,
            ...(pageName && { page_name: pageName }),
          });
        }
      },
      getDefaultEventProps: function () {
        return {
          ...getRouteProps(),
        };
      },
      getRouteProps,
    };
  },
]);
