import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MoreVert } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { useState } from 'react';
import { GoalMenu } from '../../molecules/GoalMenu';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { useTranslation } from 'react-i18next';
const goalsReportUrl = '#/reporting/quests';
export const GoalHeaderActions = ({ onSelectGoal, reportingDataPending, selectedGoalId, enableViewProgressReport = true, }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learningProgress' });
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const { emitEvent } = useDashboardAnalytics();
    const handleViewReportClick = (event) => {
        // Prevent the default link navigation
        event.preventDefault();
        emitEvent('clicked_view_goal_report_button');
        window.location.href = getProgressReportUrl();
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelectGoal = () => {
        onSelectGoal();
        handleClose();
    };
    const getProgressReportUrl = () => {
        if (!selectedGoalId)
            return goalsReportUrl;
        const content = {
            hasSelection: true,
            questIds: [selectedGoalId],
        };
        return `${goalsReportUrl}?content=${encodeURIComponent(JSON.stringify(content))}`;
    };
    return (_jsxs(Stack, { direction: "row", gap: 2, flexGrow: 1, justifyContent: "flex-end", flexShrink: 0, children: [enableViewProgressReport && (_jsx(Button, { variant: "text", disabled: reportingDataPending, href: goalsReportUrl, onClick: handleViewReportClick, children: t('viewProgressReport') })), _jsx(Button, { centerIcon: _jsx(MoreVert, {}), variant: "text", onClick: handleClick, "aria-controls": menuOpen ? 'goal-menu' : undefined, "aria-haspopup": "true", "aria-expanded": menuOpen ? 'true' : undefined }), _jsx(GoalMenu, { anchorEl: anchorEl, open: menuOpen, onClose: handleClose, onSelectGoal: handleSelectGoal })] }));
};
