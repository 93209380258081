import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { CardTemplate } from '../../../templates/CardTemplate';
import { GoalSelectionModal } from './GoalSelectionModal';
import { GoalHeaderActions } from './GoalHeaderActions';
import { GoalProgressContent } from '../../molecules/GoalProgressContent';
import { GoalPendingContent } from '../../molecules/GoalPendingContent';
import { useState } from 'react';
import { useDashboard } from '../../../context/dashboardContext';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { Trans } from 'react-i18next';
const SELECTED_GOAL_KEY = 'dashboard-selected-goal';
export const LearningProgressActive = ({ goals = [], selectedGoal, reportingData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { refreshCard } = useDashboard();
    const { emitEvent } = useDashboardAnalytics();
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };
    const handleSave = (goalId) => {
        const newSelectedGoal = goals.find((goal) => goal.goalId === goalId);
        if (newSelectedGoal) {
            localStorage.setItem(SELECTED_GOAL_KEY, goalId);
            refreshCard('learning-progress');
            // Fire analytics event
            emitEvent('changed_goal');
        }
        setModalOpen(false);
    };
    return (_jsxs(CardTemplate, { children: [_jsxs(CardTemplate.Header, { children: [_jsx(Typography, { variant: "h5", flexWrap: "wrap", children: _jsx(Trans, { i18nKey: "pages.administration.dashboard.learningProgress.title", values: { goalName: selectedGoal === null || selectedGoal === void 0 ? void 0 : selectedGoal.name } }) }), _jsx(GoalHeaderActions, { onSelectGoal: handleModalOpen, reportingDataPending: !reportingData, selectedGoalId: selectedGoal === null || selectedGoal === void 0 ? void 0 : selectedGoal.goalId })] }), _jsx(GoalSelectionModal, { open: modalOpen, onClose: handleModalClose, onSave: handleSave, goals: goals, selectedGoalId: selectedGoal === null || selectedGoal === void 0 ? void 0 : selectedGoal.goalId }), _jsx(CardTemplate.Body, { children: !reportingData ? (_jsx(GoalPendingContent, { currentGoal: selectedGoal })) : (_jsx(GoalProgressContent, { reportingData: reportingData })) })] }));
};
