var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { AppBar as MuiAppBar, Box as MuiBox, List as MuiList, styled, useMediaQuery } from '@mui/material';
import { MenuOutlined } from '@mui/icons-material';
import { Link, IconButton, Unstable_Logo } from '@securecodewarrior/design-system-react/lib/legacy';
import { AppBarMobileContext } from './AppBar.context';
import { appBarStyles } from './AppBar.styles';
import { useTranslation } from 'react-i18next';
// =============================================================
// Styled Components
// =============================================================
const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'opened' && prop != 'mobile',
})(({ mobile = false, opened = false, theme }) => (Object.assign({}, appBarStyles(mobile, opened, theme).root)));
const StyledList = styled(MuiList, {
    shouldForwardProp: (prop) => prop !== 'opened',
})(({ opened = false, theme }) => (Object.assign({}, appBarStyles(true, opened, theme).list)));
// =============================================================
// Mobile
// =============================================================
export const AppBarMobile = (props) => {
    const { t } = useTranslation();
    const { children, className = '', logoProps: _logoProps } = props, rest = __rest(props, ["children", "className", "logoProps"]);
    const [mobileContextValue, setMobileContextValue] = useState({
        isOpened: false,
        menuOpened: '',
    });
    const handleClick = useCallback(() => {
        setMobileContextValue((prevState) => (Object.assign(Object.assign({}, prevState), { isOpened: !prevState.isOpened })));
    }, []);
    const handleSetOpenMenu = useCallback((menuOpened) => {
        setMobileContextValue((prevState) => (Object.assign(Object.assign({}, prevState), { menuOpened: menuOpened === prevState.menuOpened ? '' : menuOpened })));
    }, []);
    const handleSetOptionSelected = useCallback(() => {
        setMobileContextValue((prevState) => (Object.assign(Object.assign({}, prevState), { isOpened: false, menuOpened: '' })));
    }, []);
    return (_jsx(AppBarMobileContext.Provider, { value: Object.assign(Object.assign({}, mobileContextValue), { setOpenMenu: handleSetOpenMenu, setOptionSelected: handleSetOptionSelected }), children: _jsx(StyledAppBar, Object.assign({ className: `ScwAppBar ${className}`, mobile: true, opened: mobileContextValue.isOpened }, rest, { children: _jsxs(MuiBox, { component: "nav", "aria-label": t('components.organisms.appbar.mainNavigation'), children: [_jsx(IconButton, { onClick: handleClick, className: "ScwHamburger", children: _jsx(MenuOutlined, { fontSize: "inherit" }) }), children && (_jsx(StyledList, Object.assign({ opened: mobileContextValue.isOpened }, rest, { children: children })))] }) })) }));
};
// =============================================================
// Desktop
// =============================================================
export const AppBarDesktop = (props) => {
    const { t } = useTranslation();
    const { className = '', children } = props, _a = props.logoProps, { href } = _a, logoProps = __rest(_a, ["href"]), rest = __rest(props, ["className", "children", "logoProps"]);
    return (_jsx(StyledAppBar, Object.assign({ className: `ScwAppBar ${className}` }, rest, { children: _jsxs(MuiBox, { component: "nav", "aria-label": t('components.organisms.appbar.mainNavigation'), children: [_jsx(Link, { href: href, className: "ScwAppBar-logoLink", children: _jsx(Unstable_Logo, Object.assign({}, logoProps)) }), children] }) })));
};
// =============================================================
// AppBar
// =============================================================
const AppBarComponent = (props) => {
    const isDesktopLayout = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    return isDesktopLayout ? _jsx(AppBarDesktop, Object.assign({}, props)) : _jsx(AppBarMobile, Object.assign({}, props));
};
AppBarComponent.displayName = 'AppBar';
AppBarComponent.defaultProps = {
    position: 'fixed',
};
export const Unstable_AppBar = React.memo(AppBarComponent);
/** @deprecated use {@link Unstable_AppBar} to indicate that you are aware that this component will change */
export const AppBar = Unstable_AppBar;
export default AppBar;
