var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Card } from '@securecodewarrior/design-system-react/lib/labs/atoms/Card/Card';
import { Stack } from '@mui/material';
const Header = ({ children, sx }) => (_jsx(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: Object.assign({ containerName: 'header', containerType: 'inline-size', minHeight: 36 }, sx), children: children }));
const Body = ({ children, sx }) => (_jsx(Stack, { gridTemplateColumns: "1fr 1fr", display: "grid", gap: 9, sx: Object.assign({ containerName: 'body', containerType: 'inline-size', ['@container card-container (width < 400px)']: { gridTemplateColumns: '1fr' } }, sx), children: children }));
const CardTemplate = (_a) => {
    var { children, sx } = _a, props = __rest(_a, ["children", "sx"]);
    return (_jsx(Card, Object.assign({ sx: Object.assign({ minHeight: 268, maxWidth: 900, containerName: 'card-container', containerType: 'inline-size' }, sx) }, props, { children: _jsx(Stack, { direction: "column", gap: 5, children: children }) })));
};
CardTemplate.Header = Header;
CardTemplate.Body = Body;
export { CardTemplate };
