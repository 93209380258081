import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from '../../atoms/Skeleton';
import { Box, Stack } from '@mui/system';
import { CardTemplate } from '../../../templates/CardTemplate';
import { BarChartSkeleton } from '@securecodewarrior/design-system-react/lib/labs';
export const LearnerEngagementSkeleton = () => (_jsxs(CardTemplate, { children: [_jsx(CardTemplate.Header, { children: _jsx(Skeleton, { width: 200, height: 32 }) }), _jsxs(CardTemplate.Body, { children: [_jsx(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: _jsx(Box, { sx: { width: '100%', height: '100%' }, children: _jsx(BarChartSkeleton
                        // TODO: fix height once design system bar chart skeleton is updated
                        , { 
                            // TODO: fix height once design system bar chart skeleton is updated
                            height: 212, skeletonDataProps: {
                                series: [{ data: [75, 120, 120] }],
                            } }) }) }), _jsx(Stack, { direction: "column", gap: 5, children: _jsxs(Stack, { gap: 4, children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", gap: 4, children: [_jsx(Skeleton, { height: 10, width: "40%" }), _jsx(Skeleton, { height: 10, width: "20%" })] }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", gap: 4, children: [_jsx(Skeleton, { height: 10, width: "40%" }), _jsx(Skeleton, { height: 10, width: "20%" })] }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", gap: 4, children: [_jsx(Skeleton, { height: 10, width: "40%" }), _jsx(Skeleton, { height: 10, width: "20%" })] })] }) })] })] }));
