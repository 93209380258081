import { z } from 'zod';
export const COURSE_STATE_KEY = 'course-state';
const courseStateSchema = z.object({
    expandedTopics: z.record(z.array(z.string())),
});
const readCourseState = () => {
    try {
        const expandedTopics = window.localStorage.getItem(COURSE_STATE_KEY);
        if (expandedTopics !== null) {
            return courseStateSchema.parse(JSON.parse(expandedTopics));
        }
    }
    catch (e) {
        console.error(e);
    }
    return { expandedTopics: {} };
};
export const getCourseState = (course) => {
    var _a, _b;
    const stored = readCourseState();
    if (course.chapters.length !== 0 && course.chapters[0].topics.length !== 0) {
        if (course.language.id in stored.expandedTopics) {
            // remove invalid categories
            const validCategories = course.chapters.flatMap((chapter) => chapter.topics.map((topic) => topic.category));
            stored.expandedTopics[course.language.id] = stored.expandedTopics[course.language.id].filter((x) => validCategories.includes(x));
        }
        if (((_b = (_a = stored.expandedTopics[course.language.id]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) === 0) {
            const firstTopic = course.chapters[0].topics[0];
            stored.expandedTopics[course.language.id] = [firstTopic.category];
        }
        saveCourseState(stored);
    }
    return stored;
};
export const saveCourseState = (state) => {
    const stored = readCourseState();
    try {
        window.localStorage.setItem(COURSE_STATE_KEY, JSON.stringify(Object.assign(Object.assign({}, stored), state)));
    }
    catch (e) {
        console.error(e);
    }
};
export const updateTopicState = (category, lang) => {
    var _a;
    const stored = readCourseState();
    if ((_a = stored.expandedTopics[lang]) === null || _a === void 0 ? void 0 : _a.includes(category)) {
        stored.expandedTopics[lang] = stored.expandedTopics[lang].filter((x) => x !== category);
    }
    else {
        stored.expandedTopics[lang].push(category);
    }
    saveCourseState(stored);
};
