import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { CardTemplate } from '../../../templates/CardTemplate';
import { useTranslation, Trans } from 'react-i18next';
import { platformLocaleToBcp47 } from '../../../../..';
import { getOrdinal } from '../../../../utils/getOrdinal';
import { usePlatformContext } from '../../../../platformContext';
export const TrustScoreEmpty = () => {
    var _a, _b, _c;
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.trustScore' });
    const ctx = usePlatformContext();
    return (_jsxs(CardTemplate, { children: [_jsx(CardTemplate.Header, { children: _jsx(Typography, { variant: "h5", flexWrap: "wrap", children: t('title') }) }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", gap: 4, children: [_jsx(RadialChart, { data: { value: 0, type: 'fraction', total: 1000, animate: true, name: 'value' }, dataKey: "value", domain: [0, 1000] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3) }, children: _jsx(Typography, { textAlign: "center", color: "container.border.default", children: _jsx(Trans, { i18nKey: "pages.administration.dashboard.trustScore.globalRank", values: {
                                            percentile: platformLocaleToBcp47((_c = (_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.subtitlesLanguage) === 'en-US'
                                                ? getOrdinal(100)
                                                : '100',
                                        } }) }) })] }), _jsxs(Stack, { gap: 4, children: [_jsx(Typography, { variant: "h6", children: t('yourTrustScoreWillShowHere') }), _jsx(Typography, { children: t('description1') }), _jsx(Typography, { children: t('description2') })] })] })] }));
};
