import { jsx as _jsx } from "react/jsx-runtime";
import { LearningProgressActive } from './LearningProgressActive';
import { LearningProgressEmpty } from './LearningProgressEmpty';
import { LearningProgressSkeleton } from './LearningProgressSkeleton';
import { useCardState } from '../../../context/dashboardContext';
import { LearningProgressArchived } from './LearningProgressArchived';
export const LearningProgress = () => {
    const { loading, error, data } = useCardState('learning-progress');
    if (loading || error) {
        return _jsx(LearningProgressSkeleton, {});
    }
    if (data === null || data === void 0 ? void 0 : data.onboarding) {
        return _jsx(LearningProgressEmpty, {});
    }
    if (data === null || data === void 0 ? void 0 : data.selectedGoal) {
        const { status } = data.selectedGoal;
        if (status === 'archived') {
            return _jsx(LearningProgressArchived, { goals: data === null || data === void 0 ? void 0 : data.goals });
        }
    }
    return (_jsx(LearningProgressActive, { goals: data === null || data === void 0 ? void 0 : data.goals, selectedGoal: data === null || data === void 0 ? void 0 : data.selectedGoal, reportingData: data === null || data === void 0 ? void 0 : data.goalsReportingData }));
};
