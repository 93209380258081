import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { usePlatformContext } from '../../../platformContext';
import { QuestActivity } from '../../../quests/activities/QuestActivity';
import { learnAnalytics } from '../learn.analytics';
export const CourseActivityFromRouteParams = () => {
    const { courseId, chapterNr: cNr, topicNr: tNr, activityType, activityNr: aNr } = useParams();
    const chapterNr = parseInt(cNr !== null && cNr !== void 0 ? cNr : '');
    const topicNr = parseInt(tNr !== null && tNr !== void 0 ? tNr : '');
    const activityNr = parseInt(aNr !== null && aNr !== void 0 ? aNr : '');
    if (!courseId ||
        (activityType !== 'video' &&
            activityType !== 'guideline' &&
            activityType !== 'challenge' &&
            activityType !== 'coding_lab' &&
            activityType !== 'mission' &&
            activityType !== 'walkthrough') ||
        isNaN(chapterNr) ||
        isNaN(topicNr) ||
        isNaN(activityNr)) {
        throw new Error('Invalid parameters');
    }
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => learnAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const props = {
        questId: courseId,
        chapterNr,
        topicNr,
        activityType,
        activityNr,
        analytics,
        analyticsScope: 'learn',
        returnBasePath: `learn/course/${courseId}`,
        nextActivityBasePath: `learn/course/${courseId}`,
    };
    return _jsx(QuestActivity, Object.assign({}, props));
};
