import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PersonAddAlt1 } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { CardTemplate } from '../../../templates/CardTemplate';
import { usePlatformContext } from '../../../../platformContext';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { useTranslation } from 'react-i18next';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { usePlatformNavigate } from '../../../../navigation';
export const LicenceUsageEmpty = () => {
    var _a, _b;
    const ctx = usePlatformContext();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.licenceUsage' });
    const companyPage = `#/admin/company/${(_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b._cid}/`;
    const { emitEvent } = useDashboardAnalytics();
    const navigate = usePlatformNavigate();
    const clickAddUsersButton = (event) => {
        // Prevent the default link navigation so the event can fire
        event.preventDefault();
        emitEvent('clicked_add_users_button');
        navigate.toState('admin');
    };
    return (_jsxs(CardTemplate, { children: [_jsx(CardTemplate.Header, { children: _jsx(Typography, { variant: "h5", children: t('title') }) }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: [_jsx(RadialChart, { data: { value: 0, type: 'percentage', animate: true, name: 'value' }, dataKey: "value", domain: [0, 100] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3), height: 38, width: 200 } })] }), _jsx(Card, { level: 2, padding: 4, display: "grid", sx: { placeItems: 'center' }, children: _jsxs(Stack, { alignItems: "center", justifyContent: "center", gap: 6, children: [_jsxs(Stack, { direction: "column", gap: 2, alignItems: "center", justifyContent: "center", children: [_jsx(Typography, { variant: "h6", textAlign: "center", children: t('youDontHaveUsers') }), _jsx(Typography, { textAlign: "center", children: t('inviteThem') })] }), _jsx(Button, { startIcon: _jsx(PersonAddAlt1, {}), variant: "outlined", href: companyPage, onClick: clickAddUsersButton, children: t('addUsers') })] }) })] })] }));
};
