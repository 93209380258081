import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Box, Stack, inputAdornmentClasses } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { StatusIcon } from '../../../common/icons/StatusIcon';
import { useState, useMemo } from 'react';
import { ActivityRow } from '../../../quests/ActivityRow';
import { OptionSelect } from '@securecodewarrior/design-system-react/lib/wanda';
import { topicCardAnalytics } from './TopicCard.analytics';
import { usePlatformContext } from '../../../platformContext';
import { getCategoryName, getLanguageInformation } from '../../../utils/mapper';
import { Divider } from '../../../Divider';
import { Skeleton } from '@securecodewarrior/design-system-react';
export const TopicCard = (props) => {
    var _a;
    const { t } = useTranslation();
    const { logAnalyticsEvent, metadata } = usePlatformContext();
    const analytics = useMemo(() => topicCardAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const [selectedLanguage, setSelectedLanguage] = useState(!props.completed && props.activitiesByCourse.some((x) => x.courseLanguage === props.selectedCourse)
        ? props.selectedCourse
        : undefined);
    const selectedLanguageActivities = props.completed
        ? undefined
        : props.activitiesByCourse.find((x) => x.courseLanguage === selectedLanguage);
    const backupLanguage = (selectedLanguageActivities === null || selectedLanguageActivities === void 0 ? void 0 : selectedLanguageActivities.backupLanguage)
        ? getLanguageInformation(selectedLanguageActivities.backupLanguage, metadata)
        : undefined;
    return (_jsxs(Box, { bgcolor: (theme) => (props.completed ? theme.palette.container.fill.body : theme.palette.container.fill.card1), sx: (theme) => topicCardContainerSx(theme), children: [_jsx(Stack, { direction: "row", alignItems: "center", children: _jsxs(Stack, { sx: (theme) => ({
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        flexGrow: 1,
                        minHeight: theme.typography.pxToRem(72),
                        py: 4,
                        px: 7,
                        gap: 2,
                    }), children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 1, children: [props.completed && (_jsx(Stack, { width: (theme) => theme.typography.pxToRem(46), alignItems: "center", children: _jsx(StatusIcon, { status: "passed" }) })), _jsx(Typography, { variant: "h6", component: "h3", children: getCategoryName(props.category, metadata) })] }), _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "center", gap: 2, children: [_jsx(Typography, { variant: "caption", children: t('pages.myQuests.topicCard.relatedQuests', { count: props.relatedQuests.length }) }), _jsx(Stack, { direction: "row", gap: 1, overflow: "hidden", flexShrink: 0, children: props.knownQuests
                                        .filter((q) => props.relatedQuests.includes(q.id))
                                        .map((q, index) => (_jsx(Box, { component: "img", src: q.imageUrl, "aria-label": q.name, sx: (theme) => ({
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                            // this logic is temp until design figures out how they want this
                                            // prevents the UI from breaking for now
                                            display: { xs: index > 2 ? 'none' : 'block', md: 'block' },
                                            width: 40,
                                            height: 40,
                                            borderRadius: theme.shape.border.radius.sm,
                                        }) }, `${q.name}-${index}`))) })] })] }) }), !props.completed && selectedLanguage && (_jsxs(Stack, { gap: 5, px: 7, pb: 7, children: [_jsx(Box, { sx: {
                            maxWidth: '350px',
                            [`.${inputAdornmentClasses.root}`]: {
                                display: 'none',
                            },
                        }, children: _jsx(OptionSelect, { label: "", value: selectedLanguage, onChange: (val) => setSelectedLanguage((prev) => {
                                analytics.onLanguageChanged(val, prev !== null && prev !== void 0 ? prev : 'none', props.category);
                                return val;
                            }), displayFn: (val) => val.label, options: Object.fromEntries(props.activitiesByCourse.map((x) => [
                                x.courseLanguage,
                                getLanguageInformation(x.courseLanguage, metadata),
                            ])) }) }), _jsx(Stack, { gap: 2, role: "list", children: selectedLanguageActivities === null || selectedLanguageActivities === void 0 ? void 0 : selectedLanguageActivities.activities.training.map((activity) => (_jsx(ActivityRow, { activity: activity, backupLanguage: backupLanguage, kind: "activities", onClick: () => {
                                var _a;
                                props.onActivityClicked(activity);
                                analytics.onActivityClicked(activity.id, activity.kind, (_a = activity.finished) !== null && _a !== void 0 ? _a : false);
                            } }, activity.id))) }), ((_a = selectedLanguageActivities === null || selectedLanguageActivities === void 0 ? void 0 : selectedLanguageActivities.activities.validation.length) !== null && _a !== void 0 ? _a : 0) > 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(Typography, { variant: "caption", sx: (theme) => ({
                                    '& > strong': { color: theme.palette.text.soft },
                                    color: theme.palette.text.body,
                                }), children: _jsx(Trans, { i18nKey: "pages.quests.quizDescription" }) }), _jsx(Stack, { gap: 2, role: "list", mt: -1, children: selectedLanguageActivities === null || selectedLanguageActivities === void 0 ? void 0 : selectedLanguageActivities.activities.validation.map((activity) => (_jsx(ActivityRow, { activity: activity, backupLanguage: backupLanguage, kind: "quiz", onClick: () => {
                                        var _a;
                                        props.onActivityClicked(activity);
                                        analytics.onActivityClicked(activity.id, activity.kind, (_a = activity.finished) !== null && _a !== void 0 ? _a : false);
                                    } }, activity.id))) })] }))] }))] }));
};
export const TopicSectionProgressText = ({ name, activities }) => (_jsxs(Typography, { children: [_jsx(Box, { component: "span", display: "inline", fontWeight: 700, children: activities.filter((x) => x.finished).length }), "/", activities.length, "\u00A0", name] }));
export const TopicCardSkeleton = ({ opacity }) => (_jsx(Skeleton, { sx: (theme) => (Object.assign(Object.assign({}, topicCardContainerSx(theme)), { height: theme.typography.pxToRem(72), transform: 'none', opacity })) }));
const topicCardContainerSx = (theme) => ({
    borderRadius: theme.shape.border.radius.md,
    border: `1px solid ${theme.palette.container.border.default}`,
    my: 5,
    '&:first-of-type': {
        marginTop: 10,
    },
    '&:last-of-type': {
        marginBottom: 10,
    },
});
