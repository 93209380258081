import { jsx as _jsx } from "react/jsx-runtime";
import { useCardState } from '../../../context/dashboardContext';
import { LearnerEngagementActive } from './LearnerEngagementActive';
import { LearnerEngagementOnboarding } from './LearnerEngagementOnboarding';
import { LearnerEngagementSkeleton } from './LearnerEngagementSkeleton';
export const LearnerEngagement = () => {
    const { loading, error, data } = useCardState('learner-engagement');
    if (loading || error) {
        return _jsx(LearnerEngagementSkeleton, {});
    }
    if (!data) {
        return _jsx(LearnerEngagementOnboarding, {});
    }
    return _jsx(LearnerEngagementActive, Object.assign({}, data));
};
