import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
export const getTaskTimeLeft = (task) => { var _a; return ((task === null || task === void 0 ? void 0 : task.completed) ? 0 : (_a = task === null || task === void 0 ? void 0 : task.timeLeft) !== null && _a !== void 0 ? _a : 0); };
const isTaskVisibleForFilteredQuest = (filteredQuest) => (task) => {
    return filteredQuest ? task.relatedQuests.includes(filteredQuest) : true;
};
export const getTasksTimeLeft = (tasks) => {
    return tasks.reduce((a, c) => a + getTaskTimeLeft(c), 0);
};
export const getVisibleTasks = (tasks, filteredQuest) => {
    return tasks.filter(isTaskVisibleForFilteredQuest(filteredQuest));
};
export const getFilteredTasksTimeLeft = (tasks, filteredQuest) => {
    return getTasksTimeLeft(getVisibleTasks(tasks, filteredQuest));
};
export const trainingInformationPropsFor = (tasks, filteredQuest) => {
    const visibleTasks = getVisibleTasks(tasks, filteredQuest);
    return {
        minutesLeft: getTasksTimeLeft(visibleTasks),
        nrOfCompletedTopics: visibleTasks.filter((t) => t.completed).length,
        nrOfTopics: visibleTasks.length,
    };
};
export const determineSkillLevelVariant = (questPropsKind, hasMandatoryTasks) => {
    switch (questPropsKind) {
        case 'skeleton':
            return undefined;
        case 'tasklist':
            return hasMandatoryTasks ? 'mandatory' : 'optional';
        case 'no languages selected':
        case 'all caught up':
            return 'completed';
        default:
            return assertNever(questPropsKind, 'invalid object');
    }
};
