import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckRounded } from '@mui/icons-material';
import { Stack, SvgIcon } from '@mui/material';
export const AllCaughtUpIcon = (props) => {
    return (_jsxs(Stack, { direction: "row", sx: (theme) => ({
            width: props.width,
            height: props.height,
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.soft,
            position: 'relative',
        }), children: [_jsx(SvgIcon, Object.assign({}, props, { sx: {
                    width: props.width,
                    height: props.height,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "216", height: "150", viewBox: "0 0 216 150", fill: "none", children: [_jsx("mask", { id: "mask0_2090_4155", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "216", height: "150", children: _jsx("path", { d: "M215.861 0H0.13916V150H215.861V0Z", fill: "white" }) }), _jsxs("g", { mask: "url(#mask0_2090_4155)", children: [_jsx("path", { d: "M176.855 9.93457C178.8 9.93457 180.376 8.36298 180.376 6.42432C180.376 4.48566 178.8 2.91406 176.855 2.91406C174.911 2.91406 173.335 4.48566 173.335 6.42432C173.335 8.36298 174.911 9.93457 176.855 9.93457Z", fill: "#7058F3" }), _jsx("path", { d: "M196.185 50.9141C198.129 50.9141 199.705 49.3425 199.705 47.4038C199.705 45.4652 198.129 43.8936 196.185 43.8936C194.241 43.8936 192.665 45.4652 192.665 47.4038C192.665 49.3425 194.241 50.9141 196.185 50.9141Z", fill: "#0BCCE2" }), _jsx("path", { d: "M210.107 16.8937C212.908 16.8937 215.178 14.6297 215.178 11.837C215.178 9.04424 212.908 6.78027 210.107 6.78027C207.307 6.78027 205.036 9.04424 205.036 11.837C205.036 14.6297 207.307 16.8937 210.107 16.8937Z", fill: "#FFBE12" }), _jsx("path", { d: "M23.7624 69.4707C25.7065 69.4707 27.2826 67.8991 27.2826 65.9605C27.2826 64.0218 25.7065 62.4502 23.7624 62.4502C21.8182 62.4502 20.2422 64.0218 20.2422 65.9605C20.2422 67.8991 21.8182 69.4707 23.7624 69.4707Z", fill: "#7058F3" }), _jsx("path", { d: "M26.082 105.038C28.0261 105.038 29.6022 103.466 29.6022 101.528C29.6022 99.5892 28.0261 98.0176 26.082 98.0176C24.1378 98.0176 22.5618 99.5892 22.5618 101.528C22.5618 103.466 24.1378 105.038 26.082 105.038Z", fill: "#FFBE12" }), _jsx("path", { d: "M4.77834 93.378C7.34048 93.378 9.41751 91.3009 9.41751 88.7388C9.41751 86.1766 7.34048 84.0996 4.77834 84.0996C2.21619 84.0996 0.13916 86.1766 0.13916 88.7388C0.13916 91.3009 2.21619 93.378 4.77834 93.378Z", fill: "#0BCCE2" })] })] }) })), _jsx(Stack, { sx: (theme) => ({
                    borderRadius: '50%',
                    border: `1px solid ${theme.palette.container.border.default}`,
                    backgroundColor: theme.palette.container.fill.card1,
                    width: theme.typography.pxToRem(140),
                    height: theme.typography.pxToRem(140),
                    justifyContent: 'center',
                    alignItems: 'center',
                }), children: _jsx(CheckRounded, { sx: (theme) => ({
                        fontSize: theme.typography.pxToRem(80),
                    }) }) })] }));
};
