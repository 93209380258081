import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { QuestEditorObjectivesSection } from './QuestEditorObjectivesSection';
import { QuestEditorGeneralAndBadgeSection } from './QuestEditorGeneralAndBadgeSection';
import { QuestEditorOtherSettingsSection } from './QuestEditorOtherSettingsSection';
import { QuestEditorUserAssignmentSection } from './QuestEditorUserAssignmentSection';
import SnackbarAlert from '../../molecules/SnackbarAlert';
import { useState } from 'react';
import { getInitialSnackbarState, getCustomVulnerabilityObjectives, includesMostCommonVulnerabilitiesObjective, getCategorySlugsFromObjectives, getObjectivesWithUpdatedCustomVulnerabilitySelection, getObjectivesWithUpdatedMostCommonVulnerabilitiesSelection, } from '../../../utils';
const QuestEditor = ({ data, availableCategories, validationErrors, editingDisabled, teamAssignmentEnabled, mostCommonVulnerabilitiesEnabled, companyTeams, onEdit, }) => {
    const selectedCategorySlugs = getCategorySlugsFromObjectives(getCustomVulnerabilityObjectives(data.objectives));
    const mostCommonVulnerabilitiesSelected = includesMostCommonVulnerabilitiesObjective(data.objectives);
    const [snackbarConfig, setSnackbarConfig] = useState(getInitialSnackbarState());
    const triggerSnackbar = (message, severity) => {
        setSnackbarConfig({ message, severity, open: true, key: new Date().getTime() });
    };
    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => (Object.assign(Object.assign({}, prev), { open: false })));
    };
    const handleCustomVulnerabilitySelectionEdit = (newCategorySlugSelection) => {
        const objectives = getObjectivesWithUpdatedCustomVulnerabilitySelection(data.objectives, newCategorySlugSelection);
        onEdit(Object.assign(Object.assign({}, data), { objectives }));
    };
    const handleMostCommonVulnerabilitiesUpdate = (selected) => {
        const objectives = getObjectivesWithUpdatedMostCommonVulnerabilitiesSelection(data.objectives, selected);
        onEdit(Object.assign(Object.assign({}, data), { objectives }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(QuestEditorGeneralAndBadgeSection, { name: data.name, badge: data.badge, status: data.status, isMandatory: data.isMandatory, validationErrors: validationErrors, editingDisabled: editingDisabled, onNameEdit: (name) => onEdit(Object.assign(Object.assign({}, data), { name })), onIsMandatoryToggle: () => onEdit(Object.assign(Object.assign({}, data), { isMandatory: !data.isMandatory })), onBadgeEdit: (badge) => onEdit(Object.assign(Object.assign({}, data), { badge })), onBadgeError: (error) => triggerSnackbar(error.message, 'error') }), teamAssignmentEnabled && (_jsx(QuestEditorUserAssignmentSection, { status: data.status, editingDisabled: editingDisabled, assignedTeamIds: data.assignedTeamIds, companyTeams: companyTeams, onSelectionEdit: (assignedTeamIds) => onEdit(Object.assign(Object.assign({}, data), { assignedTeamIds })) })), _jsx(QuestEditorObjectivesSection, { selectedCategories: selectedCategorySlugs, availableCategories: availableCategories, status: data.status, editingDisabled: editingDisabled, mostCommonVulnerabilitiesEnabled: mostCommonVulnerabilitiesEnabled, mostCommonVulnerabilitiesSelected: mostCommonVulnerabilitiesSelected, onSelectionEdit: handleCustomVulnerabilitySelectionEdit, onMostCommonVulnerabilitiesUpdate: handleMostCommonVulnerabilitiesUpdate }), _jsx(QuestEditorOtherSettingsSection, { startDate: data.startDate, endDate: data.endDate, status: data.status, validationErrors: validationErrors, editingDisabled: editingDisabled, onStartDateEdit: (startDate) => onEdit(Object.assign(Object.assign({}, data), { startDate })), onEndDateEdit: (endDate) => onEdit(Object.assign(Object.assign({}, data), { endDate })) }), _jsx(SnackbarAlert, { message: snackbarConfig.message, severity: snackbarConfig.severity, open: snackbarConfig.open, onClose: handleCloseSnackbar }, snackbarConfig.key)] }));
};
export default QuestEditor;
