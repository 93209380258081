import { jsx as _jsx } from "react/jsx-runtime";
import { LicenceUsageActive } from './LicenceUsageActive';
import { LicenceUsageEmpty } from './LicenceUsageEmpty';
import { LicenceUsageSkeleton } from './LicenceUsageSkeleton';
import { useCardState } from '../../../context/dashboardContext';
export const LicenceUsage = () => {
    const { loading, error, data } = useCardState('licence-usage');
    if (loading || error) {
        return _jsx(LicenceUsageSkeleton, {});
    }
    if (!data || (data.used === 0 && data.invited === 0)) {
        return _jsx(LicenceUsageEmpty, {});
    }
    return _jsx(LicenceUsageActive, { value: data });
};
