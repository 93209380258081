import { cacheExchange, createClient, fetchExchange } from 'urql';
export const DEV_TOKEN = 'sub~company626admin/company~5db65fc37657d007d7a62549/roles~company_admin';
const getAuthHeaders = (isDevelopment, sessionId) => {
    if (isDevelopment) {
        return {
            Authorization: `Bearer ${DEV_TOKEN}`,
        };
    }
    return sessionId
        ? {
            'X-Session-ID': sessionId,
        }
        : {};
};
export const urqlClientOptions = (isDevelopment, sessionId, reportingApiEndpoint) => {
    const headers = Object.assign({ 'X-Requested-With': 'frontend' }, getAuthHeaders(isDevelopment, sessionId));
    return {
        exchanges: [cacheExchange, fetchExchange],
        fetchOptions: {
            headers,
        },
        url: `${reportingApiEndpoint}/graphql`,
    };
};
export const getUrqlClient = (sessionId, reportingApiEndpoint) => createClient(urqlClientOptions(process.env.NODE_ENV === 'development', sessionId, reportingApiEndpoint));
