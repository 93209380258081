import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Stack, useTheme } from '@mui/material';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { DataLabel } from './DataLabel';
import { useTranslation, Trans } from 'react-i18next';
import { formatDateLongMonth } from '../../../dateHelper';
export const GoalProgressContent = ({ reportingData }) => {
    const theme = useTheme();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learningProgress' });
    const goals = `${reportingData.numberOfLearnersCompletedGoal}/${reportingData.learnersWithObjectiveAssigned}`;
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: [_jsx(RadialChart, { data: {
                            value: reportingData.percentageCompleted,
                            type: 'percentage',
                            animate: true,
                            name: 'value',
                            fill: theme.palette.graph.data.d1,
                        }, dataKey: "value", domain: [0, 100] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3) }, children: _jsx(Typography, { textAlign: "center", children: _jsx(Trans, { i18nKey: "pages.administration.dashboard.learningProgress.completedGoal", values: { goals } }) }) })] }), _jsxs(Stack, { gap: 8, children: [_jsxs(Stack, { gap: 2, children: [_jsx(DataLabel, { label: t('labels.totalAssignedLearners'), value: reportingData.totalAssignedLearners }), _jsx(DataLabel, { label: t('labels.learnersWithObjectiveAssigned'), value: reportingData.learnersWithObjectiveAssigned })] }), _jsxs(Stack, { gap: 2, children: [_jsx(DataLabel, { label: t('labels.startDate'), value: formatDateLongMonth(reportingData.startDate) }), _jsx(DataLabel, { label: t('labels.dueDate'), value: formatDateLongMonth(reportingData.endDate) })] })] })] }));
};
