var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql } from 'urql';
import { z } from 'zod';
const getMonthName = (date) => date.toLocaleString(undefined, { month: 'short' });
const activeLearnersUsageSchema = z
    .object({ count: z.array(z.number()), intervals: z.array(z.object({ endDate: z.coerce.date() })) })
    .transform(({ count, intervals }) => intervals.map(({ endDate }, idx) => ({ month: getMonthName(endDate), value: count[idx] })).slice(1));
const activityBreakdownSchema = z
    .object({
    series: z.array(z.object({ minutes: z.array(z.number()) })),
    intervals: z.array(z.object({ endDate: z.coerce.date() })),
})
    .transform(({ series }) => series.reduce((total, { minutes }) => total + minutes.slice(1).reduce((acc, min) => acc + min, 0), 0));
const graphReportSchema = z.object({
    activeLearnersUsage: activeLearnersUsageSchema,
    activityBreakdown: activityBreakdownSchema,
});
const graphDataSchema = z
    .object({ onboardingAndEngagement: z.object({ report: graphReportSchema }) })
    .transform(({ onboardingAndEngagement }) => onboardingAndEngagement.report);
const activeLearnersReportSchema = z.object({
    statistics: z.object({ activeCount: z.number(), newCount: z.number() }),
});
const activeLearnersSchema = z
    .object({ onboardingAndEngagement: z.object({ report: activeLearnersReportSchema }) })
    .transform(({ onboardingAndEngagement }) => onboardingAndEngagement.report.statistics);
const ACTIVE_LEARNERS_QUERY = gql `
  query getActiveLearners($filter: OnboardingAndEngagementReportFilterInput!) {
    onboardingAndEngagement {
      report(filter: $filter) {
        statistics {
          activeCount
          newCount
        }
      }
    }
  }
`;
const GRAPH_QUERY = gql `
  query getOnboardingAndEngagementActiveLearners($filter: OnboardingAndEngagementReportFilterInput!) {
    onboardingAndEngagement {
      report(filter: $filter) {
        activeLearnersUsage {
          count
          intervals {
            startDate
            endDate
          }
        }
        activityBreakdown {
          series {
            minutes
          }
          intervals {
            startDate
            endDate
          }
        }
      }
    }
  }
`;
const getStartTime = (offset) => {
    const startTime = new Date();
    startTime.setUTCMonth(startTime.getUTCMonth() - offset);
    startTime.setUTCDate(1);
    startTime.setUTCHours(0, 0, 0, 0);
    return startTime;
};
const fetchGraphData = (client) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, error } = yield client
        .query(GRAPH_QUERY, { filter: { startTime: getStartTime(3), endTime: new Date() } })
        .toPromise();
    if (error) {
        throw new Error(`GraphQL error: ${error.message}`);
    }
    return graphDataSchema.parse(data);
});
const fetchActiveLearners = (client) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, error } = yield client
        .query(ACTIVE_LEARNERS_QUERY, { filter: { startTime: getStartTime(2), endTime: new Date() } })
        .toPromise();
    if (error) {
        throw new Error(`GraphQL error: ${error.message}`);
    }
    return activeLearnersSchema.parse(data);
});
export const fetchLearnerEngagement = (client) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const [{ activeLearnersUsage, activityBreakdown }, { activeCount, newCount }] = yield Promise.all([
            fetchGraphData(client),
            fetchActiveLearners(client),
        ]);
        return {
            monthlyUsage: activeLearnersUsage,
            totals: {
                newLearners: newCount,
                activeLearners: activeCount,
                totalTimeSpent: activityBreakdown,
            },
        };
    }
    catch (e) {
        throw new Error(`Failed to fetch learner engagement data`);
    }
});
