import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo, useRef, useState } from 'react';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { DataGrid } from '@securecodewarrior/design-system-react/lib/labs';
import { CloseIconButton } from '../../../../common/components/atoms/CloseIconButton';
import { usePlatformContext } from '../../../platformContext';
import { questDetailsAnalytics } from '../../analytics/QuestDetails.analytics';
export const TeamAssignment = ({ questStatus, companyTeams, assignedTeamIds, onConfirm, onCancel, }) => {
    const { t } = useTranslation();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const searchTerms = useRef(['']);
    const visibleRowCount = useRef(companyTeams.length);
    const [selectedRowIds, setSelectedRowIds] = useState(assignedTeamIds.filter((teamId) => companyTeams.some((t) => t.id === teamId)));
    const columns = useMemo(() => [
        {
            field: 'name',
            headerName: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.team'),
            flex: 1,
        },
        {
            field: 'count',
            headerName: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.numberOfLearners'),
            flex: 1,
        },
    ], [t]);
    const rows = useMemo(() => companyTeams.map((team) => ({
        name: team.name,
        id: team.id,
        count: team.userCount,
    })), [companyTeams]);
    const beforeFilterChange = (model) => {
        searchTerms.current = model.quickFilterValues || [''];
    };
    const afterFilterUpdate = (rowCount) => {
        visibleRowCount.current = rowCount;
        analytics.onTeamAssignmentDialogFilterChange(questStatus, companyTeams.length, visibleRowCount.current, searchTerms.current);
    };
    const onSelectionUpdate = (updatedSelectedRowIds) => {
        analytics.onTeamAssignmentDialogSelectionChange(questStatus, companyTeams.length, visibleRowCount.current, selectedRowIds.length, updatedSelectedRowIds.length);
        setSelectedRowIds(updatedSelectedRowIds.map((id) => `${id}`).sort());
    };
    const onSortUpdate = (model) => {
        analytics.onTeamAssignmentDialogSortChange(questStatus, companyTeams.length, visibleRowCount.current, model);
    };
    const onApplySelection = () => {
        onConfirm(selectedRowIds);
    };
    return (_jsxs(Box, { bgcolor: (theme) => theme.palette.container.fill.card1, position: "relative", sx: (theme) => ({
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
            overflow: 'hidden',
            maxHeight: theme.typography.pxToRem(754),
            paddingTop: 7,
            paddingBottom: 8,
        }), children: [_jsx(CloseIconButton, { "aria-label": t('common.labels.cancel'), onClick: onCancel, sx: {
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                } }), _jsxs(Stack, { gap: 3, mb: 7, marginX: 7, children: [_jsx(Typography, { variant: "h2", component: "h1", sx: (theme) => ({ fontSize: theme.typography.pxToRem(24), fontWeight: 600 }), children: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.selectTeamsTitle') }), _jsx(Typography, { children: t('questsAdmin.components.organisms.questEditor.sections.userAssignment.selectTeamsSubtitle') })] }), _jsx(Stack, { gap: 4, marginX: 7, sx: (theme) => ({
                    flex: 1,
                    minHeight: 0,
                    marginBottom: 6,
                    '& > div': { maxHeight: theme.typography.pxToRem(454) },
                    '& input[type="checkbox"]': { marginTop: 0 },
                }), children: _jsx(DataGrid, { variant: "default", dataSource: { data: rows, loading: false }, columns: columns, name: "team-assignment-grid", columnVisibilityModel: {}, rowSelectionModel: selectedRowIds, loading: false, checkboxSelection: true, hideFooter: true, disableChildrenFiltering: true, disableChildrenSorting: true, disableColumnMenu: true, disableColumnPinning: true, disableColumnReorder: true, disableColumnResize: true, rowHeight: 44, columnHeaderHeight: 44, slotProps: {
                        toolbar: {
                            disableColumnSearch: true,
                            csvOptions: { disableToolbarButton: true },
                        },
                    }, sx: (theme) => ({
                        flex: 1,
                        minHeight: 0,
                        maxHeight: theme.typography.pxToRem(454),
                        overflow: 'visible',
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: 'unset',
                            height: 'unset',
                        },
                    }), onFilterModelChange: beforeFilterChange, onRowCountChange: afterFilterUpdate, onRowSelectionModelChange: onSelectionUpdate, onSortModelChange: onSortUpdate }) }), _jsx("div", { children: _jsx(Divider, {}) }), _jsxs(Stack, { direction: "row", justifyContent: "space-between", gap: 2, paddingX: 7, paddingTop: 7, children: [_jsx("div", { children: _jsx(Button, { variant: "outlined", onClick: onCancel, children: t('common.labels.cancel') }) }), _jsx("div", { children: _jsx(Button, { variant: "contained", sx: { textWrap: 'nowrap' }, disabled: false, onClick: onApplySelection, children: t('questsAdmin.components.organisms.questEditor.categorySelection.confirmText', {
                                count: selectedRowIds.length,
                            }) }) })] })] }));
};
