'use strict';

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { sha1 } from 'js-sha1';

export function initAmplitudeClient(window: Window, userId: string) {
  const { AMPLITUDE_APIKEY: apiKey } = window.SCW_ENV;
  if (apiKey) {
    amplitude.add(sessionReplayPlugin());
    const hashedUid = userId ? sha1(userId) : undefined;
    if (window.amplitude) {
      window.amplitude.setUserId(hashedUid);
      return;
    }
    amplitude.init(apiKey, hashedUid, {
      identityStorage: 'localStorage',
      autocapture: false,
      appVersion: window.SCW_ENV.GIT_COMMIT,
    });
    window.amplitude = amplitude;
  }
}

export function getAmplitudeSessionId(window) {
  return window.amplitude?.getSessionId();
}

export function resetAmplitudeSession(window) {
  window.amplitude.remove('sessionReplayTracking');
  window.amplitude.reset();
}
