import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ButtonBase, Divider, Menu, menuClasses, MenuItem, menuItemClasses, Stack, Typography, typographyClasses, } from '@mui/material';
import { QuestCard } from './QuestCard';
import { useTranslation } from 'react-i18next';
import { useState, useId, useRef, useEffect } from 'react';
import { Text } from '@securecodewarrior/design-system-react/lib/legacy';
import { MyQuestsContainer } from './MyQuestsContainer';
export const QuestFilterTabs = (props) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const focusItem = useRef(null);
    const unorderedQuests = (_a = props.quests) !== null && _a !== void 0 ? _a : [];
    const hasMoreThan5 = unorderedQuests.length > 5;
    const selectedQuestId = unorderedQuests.length === 1 ? unorderedQuests[0].id : (_b = props.selectedQuestId) !== null && _b !== void 0 ? _b : null;
    const indexPushedForward = useRef();
    const selectedIndex = unorderedQuests.findIndex((q) => q.id === selectedQuestId);
    // When selecting an item from the overflow menu, focus it after it is pushed forward so that screenreaders will
    // announce the selection (change) after interacting with the overflow menu by keyboard.
    const questIdRequiringFocusAfterOverflowSelect = useRef();
    useEffect(() => {
        if (focusItem.current && selectedQuestId === questIdRequiringFocusAfterOverflowSelect.current) {
            questIdRequiringFocusAfterOverflowSelect.current = undefined;
            focusItem.current.focus();
        }
    });
    const onSelectFromOverflowMenu = (questId) => {
        questIdRequiringFocusAfterOverflowSelect.current = questId;
        props.onSelect(questId);
    };
    if (selectedIndex >= 3 && hasMoreThan5) {
        indexPushedForward.current = selectedIndex;
    }
    if (((_c = indexPushedForward.current) !== null && _c !== void 0 ? _c : 0) >= unorderedQuests.length) {
        indexPushedForward.current = undefined;
    }
    let orderedQuests = unorderedQuests;
    if (indexPushedForward.current !== undefined) {
        orderedQuests = [
            ...orderedQuests.slice(0, 3),
            orderedQuests[indexPushedForward.current],
            ...orderedQuests.slice(3, indexPushedForward.current),
            ...orderedQuests.slice(indexPushedForward.current + 1),
        ];
    }
    const visibleOptions = hasMoreThan5 ? orderedQuests.slice(0, 4) : orderedQuests;
    const restOptions = hasMoreThan5 ? orderedQuests.slice(4) : [];
    const numberOfCards = 1 + visibleOptions.length + Math.min(restOptions.length, 1);
    return (_jsxs(MyQuestsContainer, { display: "flex", flexDirection: "row", alignItems: "center", gap: 5, children: [visibleOptions.length === 0 && _jsx(QuestCard, { skeleton: true, expanded: true, numberOfCards: 0 }), visibleOptions.length > 1 && (_jsxs(_Fragment, { children: [_jsx(QuestCard, { quests: unorderedQuests, expanded: selectedQuestId === null, onClick: () => props.onSelect(null), "aria-label": t('pages.myQuests.questCard.allQuests'), numberOfCards: numberOfCards }), _jsx(Divider, { "aria-hidden": true, orientation: "vertical", sx: (theme) => ({
                            border: `2px solid ${theme.palette.container.border.default}`,
                            height: theme.typography.pxToRem(104),
                            borderRadius: '2px',
                        }) })] })), visibleOptions.map((quest, index) => (_jsx(QuestCard, { quests: quest, expanded: selectedQuestId === quest.id, onClick: () => props.onSelect(quest.id), ref: selectedQuestId === quest.id ? focusItem : null, "aria-label": selectedQuestId === quest.id ? undefined : quest.name, numberOfCards: numberOfCards }, index))), restOptions.length !== 0 && _jsx(SelectTab, { onOptionSelect: onSelectFromOverflowMenu, options: restOptions })] }));
};
const SelectTab = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const a11yIdMenu = useId();
    const a11yIdButton = useId();
    const a11yIdOtherQuests = useId();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onSelectOption = (option) => {
        props.onOptionSelect(option);
        handleClose();
    };
    // This freezes the options when the menu is being closed, so items don't jump while the close animation happens
    const persistedOptions = useRef(props.options);
    if (open) {
        persistedOptions.current = props.options;
    }
    return (_jsxs(Box, { children: [_jsx(ButtonBase, { id: a11yIdButton, "aria-controls": a11yIdMenu, "aria-haspopup": "true", "aria-expanded": open, sx: (theme) => ({
                    ':focus-visible > div': {
                        outline: `1px solid ${theme.palette.clickable.default}`,
                    },
                    ':hover > div': {
                        backgroundColor: theme.palette.container.fill.card2,
                        outline: `1px solid ${theme.palette.container.border.active}`,
                    },
                }), onClick: (e) => handleClick(e), children: _jsx(Stack, { id: a11yIdOtherQuests, "aria-label": t('pages.myQuests.tabs.otherQuests'), sx: (theme) => ({
                        height: theme.typography.pxToRem(100),
                        width: theme.typography.pxToRem(100),
                        borderRadius: theme.shape.border.radius.md,
                        borderBottomLeftRadius: open ? 'unset' : undefined,
                        borderBottomRightRadius: open ? 'unset' : undefined,
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `1px solid ${open ? theme.palette.clickable.default : theme.palette.container.border.default}`,
                    }), children: _jsxs(Typography, { variant: "h2", color: (theme) => theme.palette.text.soft, component: "p", children: ["+", props.options.length] }) }) }), _jsx(Menu, { id: a11yIdMenu, anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, sx: (theme) => ({
                    [`.${menuClasses.paper}`]: {
                        marginTop: '-1px',
                        borderRadius: `0 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
                        scrollbarColor: `${theme.palette.grayscale.bg6} ${theme.palette.grayscale.bg4}`,
                        backgroundImage: 'unset',
                        scrollbarWidth: 'thin',
                        minWidth: '300px',
                    },
                    [`.${menuClasses.list}`]: {
                        backgroundColor: theme.palette.container.fill.card1,
                        borderRadius: `${theme.shape.borderRadius * 2}px 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
                        border: `1px solid ${theme.palette.clickable.default}`,
                        backgroundImage: 'unset',
                    },
                    [`.${menuItemClasses.root}.${menuItemClasses.selected}`]: {
                        backgroundColor: 'unset',
                        color: theme.palette.text.title,
                        backgroundImage: 'unset',
                        ':hover': {
                            backgroundColor: theme.palette.container.fill.card2,
                        },
                        ':focus': {
                            backgroundColor: theme.palette.container.fill.card2,
                        },
                        [`& .${typographyClasses.root}`]: {
                            color: theme.palette.text.title,
                        },
                    },
                    [`.${menuItemClasses.selected}:focus`]: {
                        backgroundColor: theme.palette.container.fill.card2,
                    },
                    [`.${menuItemClasses.focusVisible}`]: {
                        backgroundColor: theme.palette.container.fill.card2,
                        backgroundImage: 'unset',
                    },
                    [`& .${menuItemClasses.root}:not(${menuItemClasses.selected}`]: {
                        [`& .${typographyClasses.root}`]: {
                            color: theme.palette.text.soft,
                        },
                    },
                    [`.${menuItemClasses.root}:hover`]: {
                        backgroundColor: theme.palette.container.fill.card2,
                    },
                    '::-webkit-scrollbar': {
                        background: theme.palette.grayscale.bg4,
                        width: '4px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: theme.palette.grayscale.bg6,
                        borderRadius: 4,
                    },
                }), MenuListProps: {
                    'aria-labelledby': a11yIdOtherQuests,
                }, children: persistedOptions.current.map((option) => (_jsx(MenuItem, { onClick: () => onSelectOption(option.id), children: _jsx(Text, { startIcon: _jsx(Box, { sx: (theme) => ({
                                width: theme.typography.pxToRem(40),
                                height: theme.typography.pxToRem(40),
                                borderRadius: theme.shape.border.radius.sm,
                                background: `center / cover ${theme.palette.container.fill.card1}`,
                                backgroundImage: `url(${option.imageUrl})`,
                            }) }), label: option.name }) }, option.id))) })] }));
};
