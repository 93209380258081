import { jsx as _jsx } from "react/jsx-runtime";
import { MyQuestsPageView } from './MyQuestsPageView';
import { useApiClient } from '../../ApiContext';
import { useEffect, useState } from 'react';
export const MyQuestsPage = () => {
    const { trpc, handleErrorPromise } = useApiClient();
    const [languageSelectionProps, setLanguageSelectionProps] = useState();
    const [response, setResponse] = useState();
    useEffect(() => {
        handleErrorPromise(trpc.myquests.home.query(), (response) => {
            setResponse(response);
            if (response.kind === 'no languages selected') {
                const { availableLanguages, selectedLanguages } = response;
                setLanguageSelectionProps({ availableLanguages, selectedLanguages });
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const loadAvailableLanguages = () => {
        setLanguageSelectionProps('loading');
        return handleErrorPromise(trpc.quests.languageSelection.query(), setLanguageSelectionProps);
    };
    const saveSelectedLanguages = (languageIds) => {
        setResponse(undefined);
        handleErrorPromise(trpc.myquests.saveLanguageSelection.mutate(languageIds), (response) => {
            setResponse(response);
            if (languageSelectionProps && languageSelectionProps !== 'loading') {
                languageSelectionProps.selectedLanguages = languageIds;
            }
        });
    };
    return (_jsx(MyQuestsPageView, Object.assign({}, ((response !== null && response !== void 0 ? response : { kind: 'skeleton' })), { languageSelectionBackendData: languageSelectionProps, loadAvailableLanguages: loadAvailableLanguages, saveSelectedLanguages: saveSelectedLanguages, skillLevel: undefined })));
};
