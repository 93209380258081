import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Groups } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { CardTemplate } from '../../../templates/CardTemplate';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { DataLabel } from '../../molecules/DataLabel';
import { usePlatformContext } from '../../../../platformContext';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { useTranslation, Trans } from 'react-i18next';
import { usePlatformNavigate } from '../../../../navigation';
import { formatDateLongMonth } from '../../../../dateHelper';
export const LicenceUsageActive = ({ value }) => {
    var _a, _b;
    const ctx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const companyPage = `#/admin/company/${(_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b._cid}/`;
    const theme = useTheme();
    const { emitEvent } = useDashboardAnalytics();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.licenceUsage' });
    const { used, purchased, available, invited, renewalDate, uniqueUsers, disabledUsers } = value;
    const percentage = purchased > 0 ? used && (used / purchased) * 100 : 0;
    const chartData = {
        value: percentage,
        type: 'percentage',
        animate: true,
        name: 'value',
        fill: theme.palette.graph.data.d1,
    };
    const clickManageUsersButton = (event) => {
        // Prevent the default link navigation so the event can fire
        event.preventDefault();
        emitEvent('clicked_manage_users_button');
        navigate.toState('admin');
    };
    return (_jsxs(CardTemplate, { children: [_jsxs(CardTemplate.Header, { children: [_jsx(Typography, { variant: "h5", flexWrap: "wrap", children: t('title') }), _jsx(Stack, { direction: "row", gap: 2, flexGrow: 1, justifyContent: "flex-end", flexShrink: 0, children: _jsx(Button, { startIcon: _jsx(Groups, {}), variant: "text", href: companyPage, onClick: clickManageUsersButton, children: t('manageUsers') }) })] }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: [_jsx(RadialChart, { data: chartData, dataKey: "value", domain: [0, 100] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3) }, children: _jsx(Typography, { textAlign: "center", children: _jsx(Trans, { i18nKey: "pages.administration.dashboard.licenceUsage.usedPurchased", values: { used, purchased } }) }) })] }), _jsxs(Stack, { gap: 8, children: [_jsxs(Stack, { gap: 2, children: [_jsx(DataLabel, { label: t('labels.used'), value: used }), _jsx(DataLabel, { label: t('labels.invited'), value: invited }), _jsx(DataLabel, { label: t('labels.available'), value: available }), _jsx(DataLabel, { label: t('labels.purchased'), value: purchased })] }), _jsx(Stack, { gap: 2, children: _jsx(DataLabel, { label: t('labels.renewalDate'), value: formatDateLongMonth(renewalDate) }) }), _jsxs(Stack, { gap: 2, children: [_jsx(DataLabel, { label: t('labels.uniqueUsers'), value: uniqueUsers }), _jsx(DataLabel, { label: t('labels.disabledUsers'), value: disabledUsers })] })] })] })] }));
};
