import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton as MUISkeleton } from '@mui/material';
// Copied from Design System to avoid making changes that may have unintended consequences
// The default values are those of the RadialChart component
const calculateSkeletonSize = (size = 190, barSize = 16, outerRadius = '100%', innerRadius = '80%') => {
    const outerRadiusValue = typeof outerRadius === 'string' ? parseFloat(outerRadius) / 100 : outerRadius / size;
    const innerRadiusValue = typeof innerRadius === 'string' ? parseFloat(innerRadius) / 100 : innerRadius / size;
    return size * innerRadiusValue * outerRadiusValue + barSize / 2;
};
export const RadialSkeleton = (props) => {
    const height = calculateSkeletonSize(props.height, props.barSize, props.outerRadius, props.innerRadius);
    const width = calculateSkeletonSize(props.width, props.barSize, props.outerRadius, props.innerRadius);
    return (_jsx(MUISkeleton, { animation: "pulse", variant: "circular", height: height, width: width, sx: { bgcolor: ({ palette }) => palette.skeleton.default } }));
};
export const Skeleton = (props) => (_jsx(MUISkeleton, Object.assign({ animation: "pulse", variant: "rounded", sx: { background: ({ palette }) => palette.skeleton.default } }, props)));
