var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useTheme } from '@mui/material';
export const MyQuestsContainer = (props) => {
    const { children, topBorder, bottomBorder } = props, boxProps = __rest(props, ["children", "topBorder", "bottomBorder"]);
    const theme = useTheme();
    if (topBorder) {
        boxProps.borderTop = `1px solid ${theme.palette.container.border.default}`;
    }
    if (bottomBorder) {
        boxProps.borderBottom = `1px solid ${theme.palette.container.border.default}`;
    }
    return (_jsx(Box, Object.assign({ width: "100%", maxWidth: "1040px", mr: "auto", ml: "auto", px: 10 }, boxProps, { children: children })));
};
