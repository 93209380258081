var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
export const MyQuestsFullWidthBackgroundContainer = (props) => {
    const { children, topBorder, bottomBorder } = props, boxProps = __rest(props, ["children", "topBorder", "bottomBorder"]);
    return (_jsx(Box, Object.assign({ bgcolor: (theme) => theme.palette.container.fill.card1, borderTop: topBorder ? (theme) => `1px solid ${theme.palette.container.border.default}` : undefined, borderBottom: bottomBorder ? (theme) => `1px solid ${theme.palette.container.border.default}` : undefined }, boxProps, { children: children })));
};
