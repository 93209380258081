var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { CardTopStatus } from '../../../../common/components/atoms/CardTopStatus';
export const CourseStatus = (_a) => {
    var _b;
    var { sx, showMinutesLeft = true } = _a, props = __rest(_a, ["sx", "showMinutesLeft"]);
    const { t } = useTranslation();
    const clampedProgress = Math.min(Math.max(props.progressInPercent, 0), 100);
    const isCompleted = clampedProgress === 100;
    const minutesLeft = (_b = props.minutesLeft) !== null && _b !== void 0 ? _b : 0;
    const theme = useTheme();
    return (_jsxs(Stack, { flexShrink: 0, width: (theme) => theme.typography.pxToRem(200), borderRadius: (theme) => theme.shape.border.radius.md, alignItems: "center", border: (theme) => `1px solid ${theme.palette.container.border.default}`, bgcolor: (theme) => theme.palette.container.fill.card2, px: 7, pb: 7, gap: 5, sx: sx, children: [isCompleted ? (_jsx(CardTopStatus, { borderColor: theme.palette.badge.success.border, fill: theme.palette.badge.success.fill, children: _jsxs(Stack, { gap: 1, direction: "row", children: [_jsx(CheckRounded, { fontSize: "small" }), _jsx(Typography, { variant: "caption", color: "inherit", children: t('common.status.completed') })] }) })) : (_jsx(CardTopStatus, { borderColor: theme.palette.container.border.default, fill: theme.palette.container.fill.body, children: t('common.status.inProgress') })), _jsx(LanguageProgress, { language: props.language, progress: clampedProgress }), !isCompleted && showMinutesLeft && (_jsx(Typography, { variant: "caption", component: "p", color: (theme) => theme.palette.text.body, children: minutesLeft < 60
                    ? t('components.molecules.courseStatus.minutesLeft', { count: minutesLeft })
                    : t('components.molecules.courseStatus.hoursLeft', { count: Math.round(minutesLeft / 60) }) }))] }));
};
const LanguageProgress = (props) => {
    const id = useId();
    const isCompleted = props.progress === 100;
    const theme = useTheme();
    const fullCirclePathLength = 48.5 * 2 * Math.PI;
    return (_jsxs("div", { role: "progressbar", "aria-valuemax": 100, "aria-valuenow": props.progress, "aria-label": props.language.label, style: { position: 'relative' }, children: [_jsxs("svg", { width: "100%", viewBox: "0 0 100 100", strokeWidth: "3", style: { display: 'block' }, children: [_jsx("defs", { children: _jsxs("linearGradient", { id: id, x1: "0%", y1: "0%", x2: "100%", y2: "0%", children: [_jsx("stop", { offset: "0%", stopColor: "#0BCCE2" }), _jsx("stop", { offset: "100%", stopColor: "#FFBE12" })] }) }), _jsxs("g", { style: { transform: 'translate(50px, 50px) rotate(-90deg)' }, children: [_jsx("circle", { r: "48.5", fill: theme.palette.container.fill.body, stroke: theme.palette.container.border.default }), _jsx("circle", { r: "48.5", fill: "transparent", stroke: isCompleted ? `url(#${id})` : theme.palette.clickable.default, strokeDasharray: `${fullCirclePathLength * (props.progress / 100)} ${fullCirclePathLength}` })] })] }), _jsx(Box, { className: props.language.iconClassName, sx: (theme) => ({
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.palette.text.soft,
                    fontSize: theme.typography.pxToRem(80),
                }) })] }));
};
