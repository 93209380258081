import { CustomerAnalyticsLoginEvent } from '../analytics/customer-analytics-events';
import { react_router_states } from '../scw-react/enums';

async function updateUserProfile(AuthService, $scope, $log, $translate, $rootScope, ErrorHandler) {
  try {
    const updatedUser = await AuthService.updateProfileLanguage($scope.language);
    if (!updatedUser) {
      throw Error('Failed to update user profile language');
    }
    $log.debug($translate.instant('PROFILE_LANGUAGE_PREFERENCE_UPDATE_SUCCESSFUL'), updatedUser);
    $rootScope.changeLanguage($scope.language.i18nLanguagePreference);
  } catch (response) {
    ErrorHandler.addHttpError($translate.instant('UNABLE_TO_UPDATE_PROFILE_LANGUAGE_PREFERENCE'), response);
  }
}

export async function ssoTokenLoginImpl(
  token,
  domain,
  relayState,
  $rootScope,
  $scope,
  AuthService,
  $timeout,
  $translate,
  $log,
  ErrorHandler,
  AUTH_EVENTS,
  $window,
  companyContext
) {
  $rootScope.disableButton('login');
  AuthService.addLoading();
  $rootScope.appLoaded = false;
  try {
    const loggedInUser = await AuthService.ssoTokenLogin(token, domain);
    if (!loggedInUser) {
      throw Error('Failed SSO Login');
    }
    AuthService.setSSOCompanyContext(companyContext);
    if (!loggedInUser.properties.profile.hasI18nBeenSet) {
      AuthService.addLoading();
      await updateUserProfile(AuthService, $scope, $log, $translate, $rootScope, ErrorHandler);
    }
    await $scope.loginSuccess(loggedInUser, relayState);
  } catch (response) {
    $rootScope.$broadcast(AUTH_EVENTS.loginFailed);
    if (response && response.message && response.message === 'User account is not active') {
      ErrorHandler.addError($translate.instant('SSO_AUTH_FAILED_USER_ACCOUNT_NOT_ACTIVE'));
    } else {
      ErrorHandler.addError($translate.instant('SSO_AUTH_FAILED'));
    }
    $window.localStorage.removeItem('ssodomain');
    $rootScope.appLoaded = true;
  }
  AuthService.removeLoading();
  $rootScope.enableButton('login');
  $timeout(() => {
    $rootScope.appLoaded = true;
  }, 2000);
}

function clearRequestedState($rootScope) {
  $rootScope.requestedState = null;
  $rootScope.requestedStateParams = null;
}

function redirectToOriginalDestination($log, $rootScope, $state) {
  $log.debug('Trying to re-route to original destination state: ' + $rootScope.requestedState);
  const goToState = $rootScope.requestedState;
  const goToStateParams = $rootScope.requestedStateParams;
  clearRequestedState($rootScope);

  // scw-react (explore, quests, etc..) uses a catch-all *path param to so that all route definitions can be defined
  // inside the react app only. Angular router doesn't allow us to directly transition to a URL, so we stored the
  // part of the path after the top-level state and reconstruct the url here
  if (Object.values(react_router_states).includes(goToState)) {
    const path = '/' + goToState + (goToStateParams?.path ? goToStateParams.path : '');
    window.location.href = `${window.location.origin}/#${path}`;
    return;
  }
  $state.go(goToState, goToStateParams);
}

export function goToRequestedState(
  $rootScope,
  defaultRedirects,
  $state,
  AuthService,
  Session,
  USER_ROLES,
  $uibModalStack,
  $log
) {
  if (!$rootScope.requestedState) {
    defaultRedirects($rootScope, $state, AuthService, Session, USER_ROLES);
    return;
  }

  if ($rootScope.requestedState === 'login') {
    clearRequestedState($rootScope);
    defaultRedirects($rootScope, $state, AuthService, Session, USER_ROLES);
    return;
  }

  if ($rootScope.requestedState === 'new-simple-flow.flow') {
    $uibModalStack.dismissAll();
  }

  redirectToOriginalDestination($log, $rootScope, $state);
}

function isActiveUser(data, $rootScope) {
  return (
    data.status === $rootScope.metadata.constants.user.status.ENABLED ||
    data.status === $rootScope.metadata.constants.user.status.REGISTERED ||
    data.status === $rootScope.metadata.constants.user.status.READ_ONLY
  );
}

function emitLoginSuccessEvents(
  AnalyticsService,
  customerAnalyticsClient,
  AnalyticsEvents,
  $state,
  $rootScope,
  AUTH_EVENTS,
  data
) {
  AnalyticsService.logEvent(AnalyticsEvents.General.LOGIN, {
    login_type: $state.current.name,
    login_method: $state.current.name === 'sso-login' ? 'sso' : 'standard',
    login_destination: $rootScope.requestedState || 'default-redirect-state',
    login_result: 'success',
  });
  customerAnalyticsClient.logEvent(new CustomerAnalyticsLoginEvent());

  $rootScope.$broadcast(AUTH_EVENTS.loginSuccess, data);
}

function getUserName(Session) {
  if (Session.user?.properties?.profile?.isComplete && !Session.isAnonymous()) {
    return Session.user.properties.profile.name.first || null;
  }
  return null;
}

// see also login.ts getProfileFromLocalStorage
function addUserProfileToLocalStorage(Session, $window) {
  $window.localStorage.setItem('profile', JSON.stringify({ firstName: getUserName(Session), registered: true }));
}

function isReadOnlyUser(data, $rootScope) {
  return data.status === $rootScope.metadata.constants.user.status.READ_ONLY;
}

function getAuthRedirect(senseiAuthToken) {
  if (senseiAuthToken) {
    return 'sensei-auth';
  }
  return 'home';
}

function isAuthorizedPlayerWithoutCompleteProfile(Session, AuthService, USER_ROLES) {
  return !Session?.user?.properties?.profile?.isComplete && AuthService.isAuthorized(USER_ROLES.player);
}

async function redirectActiveUser(
  AnalyticsService,
  customerAnalyticsClient,
  AnalyticsEvents,
  $state,
  $rootScope,
  AUTH_EVENTS,
  data,
  $log,
  Session,
  $window,
  swal,
  $translate,
  senseiAuthToken,
  AuthService,
  USER_ROLES,
  relayState,
  SSOSAMLRelayStateService,
  goToRequestedStateHandler,
  defaultRedirects,
  $uibModalStack
) {
  emitLoginSuccessEvents(
    AnalyticsService,
    customerAnalyticsClient,
    AnalyticsEvents,
    $state,
    $rootScope,
    AUTH_EVENTS,
    data
  );

  $log.debug('Login successful', data);

  addUserProfileToLocalStorage(Session, $window);

  if (isReadOnlyUser(data, $rootScope)) {
    swal({
      title: $translate.instant('LICENSE_INACTIVE'),
      text: $translate.instant('LICENSE_INACTIVE_TEXT'),
      type: 'info',
      confirmButtonText: $translate.instant('OK'),
    });
    $state.go(getAuthRedirect(senseiAuthToken));
    return;
  }

  if (isAuthorizedPlayerWithoutCompleteProfile(Session, AuthService, USER_ROLES)) {
    const targetState = getAuthRedirect(senseiAuthToken);
    if (relayState && targetState === 'home') {
      $state.go(targetState, { relayState });
      return;
    }
    $state.go(targetState);
    return;
  }

  if (relayState) {
    await SSOSAMLRelayStateService.redirect(relayState);
    return;
  }

  goToRequestedStateHandler(
    $rootScope,
    defaultRedirects,
    $state,
    AuthService,
    Session,
    USER_ROLES,
    $uibModalStack,
    $log
  );
}

export function loginSuccessImplHelper(goToRequestedStateHandler) {
  return async (
    data,
    relayState,
    AuthService,
    $rootScope,
    AnalyticsService,
    customerAnalyticsClient,
    AnalyticsEvents,
    $state,
    $log,
    Session,
    $window,
    $translate,
    swal,
    defaultRedirects,
    $uibModalStack,
    ErrorHandler,
    AUTH_EVENTS,
    USER_ROLES,
    SSOSAMLRelayStateService
  ) => {
    const senseiAuthToken = AuthService.getSenseiAuthToken();
    if (isActiveUser(data, $rootScope)) {
      await redirectActiveUser(
        AnalyticsService,
        customerAnalyticsClient,
        AnalyticsEvents,
        $state,
        $rootScope,
        AUTH_EVENTS,
        data,
        $log,
        Session,
        $window,
        swal,
        $translate,
        senseiAuthToken,
        AuthService,
        USER_ROLES,
        relayState,
        SSOSAMLRelayStateService,
        goToRequestedStateHandler,
        defaultRedirects,
        $uibModalStack
      );
    } else {
      ErrorHandler.addError(data.statusMsg || $translate.instant('ACCOUNT_NOT_ENABLED'));
    }
  };
}

export function onSsoTokenLoginResponse(response, $window, domain, AuthService) {
  const { sessionId, user } = response.data;
  const remember = response.data.remember ?? false;

  // Logged in - Save domain in $window.localStorage
  $window.localStorage.setItem('ssodomain', domain);
  return AuthService.createSession(sessionId, user, remember);
}

export const loginSuccessImpl = loginSuccessImplHelper(goToRequestedState);
