export const learnAnalytics = (sendEvent) => ({
    onTopicCollapseToggled: (categoryId, wasCollapsed) => {
        const event = wasCollapsed ? 'Topic opened' : 'Topic closed';
        sendEvent({ event, scope: 'learn', categoryId });
    },
    onActivityStarted: (status, activityId, activityType, categoryId, preferredLanguage) => {
        sendEvent({
            event: 'Activity Started',
            scope: 'learn',
            status,
            activityId,
            activityType,
            categoryId,
            preferredLanguage,
        });
    },
    onActivityFinished: (activityId, activityType, categoryId, preferredLanguage) => {
        sendEvent({ event: 'Activity Finished', scope: 'learn', activityId, activityType, categoryId, preferredLanguage });
    },
    onNextActivityClicked: (activityId, activityType, categoryId) => {
        sendEvent({ event: 'Next Activity Clicked', scope: 'learn', activityId, activityType, categoryId });
    },
    onBackFromActivityClicked: (component, activityType) => {
        sendEvent({ event: 'Back to Learn Clicked', scope: 'learn', component, activityType });
    },
    onFirstLanguageSelection: () => {
        sendEvent({ event: 'First Language selection open', scope: 'learn' });
    },
});
