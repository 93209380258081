import { Menu, MenuItem, styled } from '@mui/material';
export const StyledGoalMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: theme.shape.borderRadius * 2,
        border: `1px solid ${theme.palette.clickable.default}`,
        backgroundColor: theme.palette.container.fill.card1,
        boxShadow: theme.shadows[1],
        minWidth: '300px',
        padding: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    '& .MuiList-root': {
        padding: 0,
        width: '100%',
    },
}));
export const StyledGoalMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1, 3),
    alignItems: 'center',
    gap: theme.spacing(1),
    alignSelf: 'stretch',
    '& .MuiMenuItem-root': {
        minHeight: 32,
    },
}));
