import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GoalMenuItem } from '../atoms/GoalMenuItem';
import { StyledGoalMenu } from '../../styled/GoalMenu.styled';
import { useTranslation } from 'react-i18next';
import { useDashboardAnalytics } from '../../hooks/useDashboardAnalytics';
const createAGoalReportUrl = '/#/admin/quests/create/';
export const GoalMenu = ({ anchorEl, open, onClose, onSelectGoal }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learningProgress' });
    const { emitEvent } = useDashboardAnalytics();
    function clickCreateAGoal(event) {
        // Prevent the default link navigation
        event.preventDefault();
        // Fire analytics event
        emitEvent('clicked_create_a_goal_button');
        // Redirect to create a goal
        window.location.href = createAGoalReportUrl;
    }
    return (_jsxs(StyledGoalMenu, { id: "goal-menu", anchorEl: anchorEl, open: open, onClose: onClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, children: [_jsx(GoalMenuItem, { label: t('selectGoal'), onClick: onSelectGoal }), _jsx(GoalMenuItem, { label: t('createGoal'), onClick: clickCreateAGoal })] }));
};
