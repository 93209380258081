export const statusKeys = ['all', 'in_progress', 'scheduled', 'finished', 'archived', 'draft'];
export const statusToBadgeMap = {
    in_progress: 'accent2',
    scheduled: 'primary',
    finished: 'accent4',
    archived: 'default',
    draft: 'default',
};
export const questsAdminUrls = {
    listPage: `/admin/quests`,
    createPage: `/admin/quests/create`,
    detailsPage: (questId) => `/admin/quests/${questId}`,
};
