import angular from 'angular';
import MODULE from './module';
import wrapperTemplate from './ReportingWrapperTemplate.html';
import { USER_ROLES as userRoles } from '../auth/constants';
import { loadReportingEntryFromURL, loadReportingModuleFromRegion } from './reporting.loader';

const app = angular.module(MODULE);

const LOADING_STATE = {
  loading: 0,
  loaded: 1,
  failed: 2,
};

//TODO: Replace this with lazy module loading and better shared dependencies in Angular 2
app.run([
  '$window',
  '$rootScope',
  function ($window, $rootScope) {
    let reportingDependenciesLoaded = false;

    $rootScope.$watch('Session.user', function (newValue) {
      const hasRequiredRole =
        newValue?.roles?.includes($rootScope.metadata.constants.user.role.COMPANY_ADMIN) ||
        newValue?.roles?.includes($rootScope.metadata.constants.user.role.TEAM_MANAGER);
      const hasCidProperty = Boolean(newValue?.properties?._cid);
      const shouldLoadReportingDependencies = !reportingDependenciesLoaded;

      if (hasRequiredRole && hasCidProperty && shouldLoadReportingDependencies) {
        reportingDependenciesLoaded = true;
        loadReportingEntryFromURL($window.SCW_ENV);
      }
    });
  },
]);

app.controller('ReportingWrapperController', [
  '$scope',
  '$window',
  '$rootScope',
  '$state',
  '$translate',
  'AuthService',
  '$location',
  function ($scope, $window, $rootScope, $state, $translate, AuthService, $location) {
    $rootScope.appLoaded = true;
    $rootScope.removePreloading = true;

    $scope.LOADING_STATE = LOADING_STATE;
    $scope.reportingModuleState = LOADING_STATE.loading;

    this.$onInit = function () {
      const session = $rootScope.Session;

      checkRootStateAndRedirect(session);
    };

    const checkRootStateAndRedirect = (session) => {
      if (session?.getUser()) {
        $scope.isAuthorized = $rootScope.isAuthorized([userRoles.companyAdmin, userRoles.manager]);
        $scope.sessionId = session.getSessionId();
        $scope.i18nLanguage = $translate.use();
        $scope.userId = session.getUser()._id;
        $scope.companyId = session.getUser().properties._cid;
        $scope.role = AuthService.mainRole(session.getUser());
        $scope.salesforceId = session.getUser().properties.company?.vleId;

        loadReporting();
      } else {
        $rootScope.requestedState = $state.current.name;
        $rootScope.requestedStateParams = JSON.parse(JSON.stringify($state.params));
        $rootScope.defaultRedirects();
      }
    };

    const loadReporting = () => {
      loadReportingModuleFromRegion($window.SCW_ENV)
        .then(() => {
          $scope.reportingModuleState = $scope.isAuthorized && LOADING_STATE.loaded;
        })
        .catch((error) => {
          console.error('Error loading reporting module', error);
          $scope.reportingModuleState = LOADING_STATE.failed;
        });
    };

    $scope.$on('$locationChangeStart', function (_event, newUrl, oldUrl) {
      if (newUrl.includes('#/reporting/') && oldUrl.includes('#/reporting/')) {
        $state.go(
          'reporting',
          { path: decodeURIComponent(newUrl.split('#/reporting/')[1]) },
          {
            notify: false,
            reload: true,
            location: false,
            inherit: false,
          }
        );
        $location.replace();
        $location.url(newUrl.split('#')[1]);
      }
    });

    $scope.$on('$stateChangeStart', function (event, toState) {
      if (toState.name === 'reporting') {
        event.preventDefault();
      }
    });
  },
]);

app.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider.state('reporting', {
      url: '/reporting/{path:any}',
      controller: 'ReportingWrapperController',
      templateUrl: wrapperTemplate,
      reloadOnSearch: false,
    });
  },
]);
