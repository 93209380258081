import angular from 'angular';
import MODULE from './module';
import newCourseTemplate from '../training/NewCourse.html';

const app = angular.module(MODULE);

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  'USER_ROLES',
  function ($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
      .state('home', {
        url: '/home?relayState',
        controller: 'CourseNewController',
        templateUrl: newCourseTemplate,
        data: {
          authorizedRoles: [USER_ROLES.companyAdmin, USER_ROLES.manager, USER_ROLES.player],
          feature: 'home',
        },
      })
      .state('home.relay-state', { url: '/relay-state/{relayState}' });
  },
]);
