import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import { ArchiveRounded, DeleteRounded, SaveRounded, SendRounded } from '@mui/icons-material';
import { Tooltip } from '../../../../common/Tooltip';
export const ActionPanel = (props) => {
    var _a;
    const { skeletonMode, isPerformingMutation, isFormDirty, status, validationErrors } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const handleSaveClick = (event) => {
        var _a;
        event.preventDefault();
        (_a = props.onSave) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const handlePublishClick = (event) => {
        var _a;
        event.preventDefault();
        (_a = props.onPublish) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const handleArchiveClick = (event) => {
        var _a;
        event.preventDefault();
        (_a = props.onArchive) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const handleDeleteClick = (event) => {
        var _a;
        event.preventDefault();
        (_a = props.onDelete) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const showArchiveButton = status === 'in_progress' || status === 'finished';
    const showDeleteButton = status === 'scheduled' || status === 'draft';
    const readOnly = !!skeletonMode || isPerformingMutation;
    return (_jsxs(Stack, { sx: {
            padding: theme.spacing(5),
            justifyContent: 'flex-start',
            width: '100%',
            height: '100%',
            gap: theme.spacing(4),
            borderLeft: `1px solid ${theme.palette.container.border.default}`,
        }, children: [showArchiveButton && (_jsx(Button, { type: "button", onClick: handleArchiveClick, startIcon: _jsx(ArchiveRounded, {}), variant: "text", disabled: readOnly, children: t('questsAdmin.pages.edit.archiveButton') })), showDeleteButton && (_jsx(Button, { type: "button", onClick: handleDeleteClick, startIcon: _jsx(DeleteRounded, {}), variant: "text", color: "warning", disabled: readOnly, children: t(status === 'draft'
                    ? 'questsAdmin.components.molecules.confirmationDialog.deleteDraftDialog.confirmButtonLabel'
                    : 'questsAdmin.pages.edit.deleteButton') })), status === 'draft' && _jsx(Divider, {}), (status === 'new' || status === 'draft') && (_jsxs(_Fragment, { children: [_jsx(Button, { type: "submit", onClick: handleSaveClick, disabled: status === 'new' ? readOnly : readOnly || !isFormDirty, startIcon: _jsx(SaveRounded, {}), variant: "text", children: status === 'new' ? t('questsAdmin.pages.edit.saveAsDraft') : t('questsAdmin.pages.edit.saveDraft') }), _jsx(Tooltip, { title: t(((_a = validationErrors[0]) === null || _a === void 0 ? void 0 : _a.message) || '') || '', arrow: true, children: _jsx(Box, { children: _jsx(Button, { type: "submit", onClick: handlePublishClick, disabled: (validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.length) > 0 || readOnly, startIcon: _jsx(SendRounded, {}), variant: "contained", sx: { width: '100%' }, children: t('questsAdmin.pages.edit.saveAndPublish') }) }) })] }))] }));
};
