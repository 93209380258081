import { z } from 'zod';
export const QUESTS_STATE_KEY = 'quests-state';
const questsStateSchema = z.object({
    selectedLanguage: z.string(),
    expandedTopics: z.record(z.array(z.string())),
});
const readQuestsState = () => {
    try {
        const expandedTopics = window.localStorage.getItem(QUESTS_STATE_KEY);
        if (expandedTopics !== null) {
            return questsStateSchema.parse(JSON.parse(expandedTopics));
        }
    }
    catch (e) {
        console.error(e);
    }
    return { selectedLanguage: '', expandedTopics: {} };
};
export const getQuestState = (quests) => {
    var _a, _b;
    const defaultLanguage = Object.keys(quests)[0];
    const stored = readQuestsState();
    if (stored.selectedLanguage === '') {
        stored.selectedLanguage = defaultLanguage;
    }
    if (!(stored.selectedLanguage in quests)) {
        stored.selectedLanguage = defaultLanguage;
    }
    for (const lang in quests) {
        if (quests[lang].chapters.length === 0)
            continue;
        if (lang in stored.expandedTopics) {
            // remove invalid categories
            const validCategories = quests[lang].chapters.flatMap((chapter) => chapter.topics.map((topic) => topic.category));
            stored.expandedTopics[lang] = stored.expandedTopics[lang].filter((x) => validCategories.includes(x));
        }
        if (((_b = (_a = stored.expandedTopics[lang]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) === 0) {
            const firstTopic = quests[lang].chapters[0].topics[0];
            stored.expandedTopics[lang] = [firstTopic.category];
        }
    }
    saveQuestState(stored);
    return stored;
};
export const saveQuestState = (state) => {
    const stored = readQuestsState();
    try {
        window.localStorage.setItem(QUESTS_STATE_KEY, JSON.stringify(Object.assign(Object.assign({}, stored), state)));
    }
    catch (e) {
        console.error(e);
    }
};
export const updateTopicState = (category) => {
    var _a;
    const stored = readQuestsState();
    if ((_a = stored.expandedTopics[stored.selectedLanguage]) === null || _a === void 0 ? void 0 : _a.includes(category)) {
        stored.expandedTopics[stored.selectedLanguage] = stored.expandedTopics[stored.selectedLanguage].filter((x) => x !== category);
    }
    else {
        stored.expandedTopics[stored.selectedLanguage].push(category);
    }
    saveQuestState(stored);
};
