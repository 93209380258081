import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useApiClient } from '../../ApiContext';
import { LearnPageView } from './LearnPageView';
import { usePlatformNavigate } from '../../navigation';
export const LearnPageWrapper = () => {
    const { handleErrorPromise, trpc } = useApiClient();
    const navigate = usePlatformNavigate();
    const [courses, setCourses] = useState(undefined);
    const [availableLanguages, setAvailableLanguages] = useState();
    const fetchCourses = () => {
        setCourses(undefined);
        handleErrorPromise(trpc.learn.course.getCourses.query(), (r) => {
            if (r.courses.length === 0) {
                handleErrorPromise(trpc.quests.languageSelection.query(), (langData) => {
                    setAvailableLanguages(langData.availableLanguages);
                });
            }
            setCourses(r.courses);
        });
    };
    useEffect(() => {
        fetchCourses();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const mutateLanguageSelection = (languageIds) => {
        return handleErrorPromise(trpc.quests.userLanguages.mutate(languageIds), () => {
            fetchCourses();
        });
    };
    const onCourseClicked = (frameworkLanguage) => navigate.to({ pathname: `/learn/course/${frameworkLanguage}`, search: '', hash: '' });
    return (_jsx(LearnPageView, { courses: courses, onCourseClicked: onCourseClicked, availableLanguages: availableLanguages, updateLanguageSelection: mutateLanguageSelection }));
};
